import React from 'react'
import PropTypes from 'prop-types'
import { AsyncPaginate } from 'react-select-async-paginate'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

import jsonApi from '../../utils/jsonApi'
import http from '../../utils/http'

const SelectAsyncPaginate = ({
  request,
  searchParam,
  placeholder,
  handleAsyncChange,
  defaultValue,
  isMulti,
  disabled,
  meta: { touched, error },
  input,
  touch,
  defaultOptions = true,
  rootStyle,
  optionsObject,
  ...rest
}) => {
  const [eventCreator, setEventCreator] = React.useState(false)
  const generalTheme = useTheme()
  const useStyles = makeStyles(theme => ({
    rootDiv: {
      // marginBottom: 14
    },
    root: {
      border: props => props.borderColor,
      backgroundColor: '#FFF',
      borderRadius: '8px',
      width: '100%',
      [theme.breakpoints.down('426')]: {
        width: 255
      },
      '& .select__input > input': {
        height: isMulti ? 0 : 48,
        paddingBottom: 12
      },
      '& .select__control, .select__value-container': {
        height: !isMulti && '48px',
        minHeight: '48px',
        paddingTop: 0,
        borderRadius: '8px !important',
        color: '#FFF'
      },
      '& .select__control': {
        border: 'none !important',
        borderRadius: '8px'
      },
      '& .select__placeholder': {
        color: '#e4e4e4',
        fontSize: '1rem'
      },
      '& .select__indicators': {
        height: 48
      },
      '& .select__single-value': {
        paddingTop: '7px',
        color: theme.palette.text.primary
      },
      '& .select__value-container > .select__multi-value': {
        justifyContent: 'space-around'
      },
      '& .select__value-container > .select__multi-value > .select__multi-value__label': {
        color: 'white'
      },
      '& .select__value-container > .select__multi-value:nth-of-type(3n-2)': {
        background: '#F4A77B',
        borderRadius: 8
      },
      '& .select__value-container > .select__multi-value:nth-of-type(3n-1)': {
        background: '#00ACDB',
        borderRadius: 8
      },
      '& .select__value-container > .select__multi-value:nth-of-type(3n)': {
        background: '#EF7B8E',
        borderRadius: 8
      }
    },
    error: {
      color: theme.palette.danger.main,
      fontSize: '0.8rem'
    },
    indicators: {
      padding: '5px 0',
      '& > button.MuiIconButton-root': {
        color: theme.palette.primary.dark,
        padding: '10px 7px'
      }
    }
  }))
  const classes = useStyles({
    borderColor:
      touched && error
        ? `1px solid ${generalTheme.palette.danger.main} !important`
        : '1px solid #ADB8CC'
  })

  React.useEffect(() => {
    if (request.path === 'events/event_creators') {
      setEventCreator(true)
    }
  }, [])

  const loadOptions = async (search, loadedOptions = '', { page }) => {
    const hasSearchParam = searchParam ? { [searchParam]: search } : ''
    const hasComplemenLabelParams = !!request.complemenLabelParams
    const hasInclude =
      request.params && request.params.hasOwnProperty('include')
    const requestParams = {
      ...hasSearchParam,
      ...(hasComplemenLabelParams && !hasInclude
        ? request.complemenLabelParams.includeOptions
        : {}),
      ...request.params,
      'page[number]': page
    }
    let response
    if (request.path !== 'events/event_creators') {
      response = await jsonApi
        .findAll(request.path, { params: requestParams })
        .then(res => res)
    } else {
      response = await http.get(request.path).then(res => res)
    }

    const options = response.data.map(data => {
      if (hasInclude) {
        // to use when the displayed value is on the include
        const includeData = data[request.params['include']]
        return {
          label: includeData?.name,
          value: includeData?.id
        }
      }
      let complementLabel = data.title || data.name
      complementLabel = optionsObject
        ? optionsObject[complementLabel]
        : complementLabel
      if (hasComplemenLabelParams) {
        complementLabel =
          complementLabel + request.complemenLabelParams.sublabel(data)
      }
      return {
        label: complementLabel,
        value: data.id
      }
    })
    // filter duplicate values
    const optionsFilter = options
      .filter(
        element => element.label !== undefined && element.value !== undefined
      )
      .filter(
        (element, index, self) =>
          index === self.findIndex(seg => seg.value === element.value)
      )

    const resp = {
      options: optionsFilter,
      additional: {
        page: page + 1
      }
    }

    if (eventCreator === false) {
      Object.assign(resp, { hasMore: response.data.length >= 1 })
    }

    return resp
  }

  const wrappedLoadOptions = (...args) => {
    return loadOptions(...args)
  }

  const ClearIndicator = ({ innerRef, innerProps }) => (
    <span ref={innerRef} {...innerProps} className={classes.indicators}>
      <IconButton>
        <HighlightOffIcon />
      </IconButton>
    </span>
  )

  const DropdownIndicator = ({ innerRef, innerProps }) => (
    <span ref={innerRef} {...innerProps} className={classes.indicators}>
      <IconButton>
        <ArrowDropDownIcon />
      </IconButton>
    </span>
  )

  return (
    <div className={`${classes.rootDiv} ${rootStyle}`}>
      <AsyncPaginate
        classNamePrefix='select'
        key={`${input.name}[${rest?.cacheUniqs}]`}
        debounceTimeout={500}
        cacheOptions
        onBlurResetsInput
        className={classes.root}
        defaultOptions={defaultOptions}
        onMouse
        isDisabled={disabled}
        isMulti={isMulti}
        isClearable
        closeMenuOnSelect={!isMulti}
        components={{
          IndicatorSeparator: () => null,
          ClearIndicator,
          DropdownIndicator
        }}
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => 'Não encontrado'}
        loadOptions={wrappedLoadOptions}
        minMenuHeight={50}
        onChange={handleAsyncChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onFocus={e => {
          touch(input.name)
        }}
        name={input.name}
        {...rest}
        additional={{
          page: 1
        }}
      />
      {touched && error && <span className={classes.error}>{error}</span>}
    </div>
  )
}

SelectAsyncPaginate.propTypes = {
  request: PropTypes.object.isRequired,
  searchParam: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.any,
  handleAsyncChange: PropTypes.func,
  meta: PropTypes.object,
  input: PropTypes.object,
  touch: PropTypes.any.isRequired,
  borderColor: PropTypes.string,
  isMulti: PropTypes.bool,
  disabled: PropTypes.bool,
  defaultOptions: PropTypes.bool,
  rootStyle: PropTypes.object,
  optionsObject: PropTypes.object
}

export default SelectAsyncPaginate

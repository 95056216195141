import jsonApi from '../../utils/jsonApi'
import http from '../../utils/http'

const chatModel = 'room'

export const fetchRoomById = id => {
  return jsonApi
    .find(chatModel, id, {
      params: {
        include: 'room_participants.user'
      }
    })
    .then(res => res)
}

export const fetchRooms = ({ page, params }) => {
  return jsonApi
    .findAll(chatModel, {
      params: {
        ...params,
        'page[number]': page || 1,
        include: 'room_participants.user'
      }
    })
    .then(res => res)
}

export const createRoom = data => {
  return http
    .post('rooms', {
      data: {
        type: 'rooms',
        attributes: {
          ...data
        }
      }
    })
    .then(res => {
      return res
    })
}

export const createMessage = async data => {
  return http
    .post('new_messages', {
      data: {
        type: 'new_messages',
        attributes: {
          ...data
        }
      }
    })
    .then(res => {
      return res
    })
}

export const getMessages = async data => {
  return http
    .get('new_messages', {
      params: {
        'q[room_id_eq]': data.roomId,
        'q[s]': 'created_at desc',
        ...data.params
      }
    })
    .then(res => {
      return res
    })
}

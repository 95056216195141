import React from 'react'
import { makeStyles } from '@material-ui/core'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    fontFamily: 'Montserrat, Source Sans Pro',
    width: '98%',
    padding: '40px 30px',
    height: '100%',
    borderRadius: 8,
    backgroundColor: '#FFFFFF'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    '& h1': {
      margin: 0,
      fontWeight: 700,
      color: '#4D5E80',
      fontSize: 32
    },
    '& p': {
      margin: 0,
      fontWeight: 400,
      fontSize: 14,
      color: '#555555',
      maxWidth: 400
    }
  },
  item: {
    cursor: 'pointer',
    padding: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '95%',
    height: 160,
    borderRadius: 20,
    color: 'white'
  },
  modules: {
    backgroundColor: '#386093',
    '& .plusIcon': {
      backgroundColor: '#386093'
    }
  },
  themes: {
    backgroundColor: '#9DD2DA',
    '& .plusIcon': {
      backgroundColor: '#9DD2DA'
    }
  },
  item__title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: 40,
    gap: 25,
    '& .index': {
      fontWeight: 700,
      fontSize: 70,
      fontFamily: 'Source Sans Pro'
    },
    '& .title': {
      fontWeight: 700,
      fontSize: 30,
      fontFamily: 'Source Sans Pro'
    }
  },
  verticalBar: {
    width: 1,
    height: 130,
    margin: 'auto 0px',
    borderLeft: '1px solid #FFFFFF'
  },
  item__description: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '55%',
    '& h1': {
      fontWeight: 400,
      fontSize: 18,
      color: '#FFFFFF',
      maxWidth: 350
    }
  },
  addRoot: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    borderRadius: '50%',
    width: 70,
    height: 70
  },
  plusIcon: {
    width: 26,
    height: 26,
    clipPath:
      'path("M11.143 14.8571V25C11.143 25.5523 11.5907 26 12.143 26H13.8573C14.4095 26 14.8573 25.5523 14.8573 25V14.8571H25C25.5523 14.8571 26 14.4094 26 13.8571V12.1429C26 11.5906 25.5523 11.1429 25 11.1429H14.8573V1C14.8573 0.447715 14.4095 0 13.8573 0H12.143C11.5907 0 11.143 0.447716 11.143 1V11.1429H1C0.447715 11.1429 0 11.5906 0 12.1429V13.8571C0 14.4094 0.447715 14.8571 1 14.8571H11.143Z")'
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    marginTop: 70
  }
}))

const SocioemotionalPage = ({ currentUser }) => {
  const classes = useStyles()
  const history = useHistory()

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <h1>Olá, {currentUser.attributes.name}</h1>
        <p>
          Seja bem-vindo(a)! Você está prestes a iniciar a jornada para a
          criação do seu projeto. Vamos começar juntos!
        </p>
      </div>
      <div className={classes.main}>
        <div
          onClick={() => {
            history.push('/newmodules')
          }}
          className={`${classes.item} ${classes.modules}`}
        >
          <div className={classes.item__title}>
            <h1 className='index'>1</h1>
            <div className={classes.verticalBar} />
            <h1 className='title'>Módulo</h1>
          </div>
          <div className={classes.item__description}>
            <h1>
              Inicie a criação do módulo para desbloquear os próximos itens.
            </h1>
          </div>
          <div className={classes.addRoot}>
            <div className={`${classes.plusIcon} plusIcon`} />
          </div>
        </div>
        <div
          onClick={() => {
            history.push('/newthemes')
          }}
          className={`${classes.item} ${classes.themes}`}
        >
          <div className={classes.item__title}>
            <h1 className='index'>2</h1>
            <div className={classes.verticalBar} />
            <h1 className='title'>Temas</h1>
          </div>
          <div className={classes.item__description}>
            <h1>Item bloqueado, finalize o módulo para desbloquear o tema.</h1>
          </div>
          <div className={classes.addRoot}>
            <div className={`${classes.plusIcon} plusIcon`} />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    currentUser: state.auth.user.data
  }
}

export default connect(mapStateToProps)(SocioemotionalPage)

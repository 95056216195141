import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import MenuApp from '../components/menu/Menu'

import RedeBertoniApple from '../assets/qrCode/QRcodeBertoni-appleStore.svg'
import RedeBertoniPlayStore from '../assets/qrCode/QRcodeBertoni-playStore.svg'

import KingdomApple from '../assets/qrCode/QRcodeKingdom-appleStore.svg'
import KingdomPlayStore from '../assets/qrCode/QRcodeKingdom-playStore.svg'

import versarAppleStore from '../assets/qrCode/QRcodeVersar-appleStore.svg'
import versarPlayStore from '../assets/qrCode/QRcodeVersar-playStore.svg'

import SeriosAppleStore from '../assets/qrCode/QRcodeSerios-appleStore.svg'
import SeriosPlayStore from '../assets/qrCode/QRcodeSerios-playStore.svg'

import appleStoreIcon from '../assets/icons/appleStoreIcon.svg'
import playStoreIcon from '../assets/icons/playStoreIcon.svg'

import notFoundCharacterBertoni from '../assets/notFoundCharacterBertoni.svg'
import notFoundCharacterKingdom from '../assets/notFoundCharacterKingdom.svg'
import notFoundCharacterVersar from '../assets/notFoundCharacterVersar.svg'
import notFoundCharacterSerios from '../assets/notFoundCharacterSerios.svg'

const useStyles = makeStyles(theme => ({
  section: {
    width: '98%',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
    '& h1': {
      color: '#4D5E80',
      fontWeight: 600,
      fontSize: '40px',
      fontFamily: 'Source Sans Pro',
      lineHeight: '40px'
    }
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#ffffff',
    borderTopLeftRadius: '8px',
    borderBottomLeftRadius: '8px',
    padding: '56px 40px 0px 70px',
    '& p': {
      fontSize: '16px',
      color: '#ADB8CC',
      fontFamily: 'Source Sans Pro',
      lineHeight: '24px',
      fontWeight: 400
    },
    '& h1': {
      margin: '0px 0px 24px 0px'
    },
    [theme.breakpoints.down(1100)]: {
      borderRadius: '8px'
    }
  },
  qrCodeContainer: {
    marginTop: '25px',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(1100)]: {
      margin: 'auto'
    },
    [theme.breakpoints.down(780)]: {
      flexDirection: 'column',
      margin: 'auto'
    },
    [theme.breakpoints.down(600)]: {
      flexDirection: 'column',
      margin: 'auto'
    }
  },
  qrCodeSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& a': {
      cursor: 'default'
    },
    '& img:first-child': {
      marginTop: '30px',
      marginBottom: '20px'
    },
    '& img:last-child': {
      cursor: 'pointer'
    }
  },
  imgQrCodeApple: {
    marginBottom: '20px',
    marginRight: '139px',
    [theme.breakpoints.down(1100)]: {
      marginRight: 100
    },
    [theme.breakpoints.down(780)]: {
      margin: 0
    }
  },
  imgQrCodePlayStore: {
    marginBottom: '20px',
    [theme.breakpoints.down(1100)]: {
      marginTop: 30
    }
  },
  character: {
    display: 'flex',
    alignSelf: 'flex-start',
    backgroundColor: '#ffffff',
    padding: '56px 40px 256px 70px',
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px',
    [theme.breakpoints.down(1400)]: {
      paddingBottom: '326px'
    },
    [theme.breakpoints.down(1100)]: {
      display: 'none'
    }
  },
  characterImg: {
    width: 500,
    [theme.breakpoints.down(1500)]: {
      width: 400,
      margin: 'auto'
    },
    [theme.breakpoints.down(1400)]: {
      width: 300,
      margin: 'auto'
    },
    [theme.breakpoints.down(1100)]: {
      display: 'none'
    }
  }
}))

const NotFoundPage = ({ userOccupations }) => {
  const appId = process.env.REACT_APP_PROJECT_ID
  const classes = useStyles()

  const getImageImports = appId => {
    let qrCodeApple,
      qrCodePlayStore,
      notFoundCharacter,
      linkPlayStore,
      linkAppleStore

    switch (appId) {
      case '42':
        qrCodeApple = RedeBertoniApple
        qrCodePlayStore = RedeBertoniPlayStore
        notFoundCharacter = notFoundCharacterBertoni
        linkPlayStore =
          'https://play.google.com/store/apps/details?id=br.com.fractaltecnologia.plataformarversar.bertoni'
        linkAppleStore =
          'https://apps.apple.com/br/app/agenda-bertoni/id1671381306'
        break
      case '43':
        qrCodeApple = KingdomApple
        qrCodePlayStore = KingdomPlayStore
        notFoundCharacter = notFoundCharacterKingdom
        linkPlayStore =
          'https://play.google.com/store/apps/details?id=br.com.fractaltecnologia.plataformarversar.kingdom'
        linkAppleStore =
          'https://apps.apple.com/br/app/kingdom-school-app/id1667198165'
        break
      case '34':
        qrCodeApple = versarAppleStore
        qrCodePlayStore = versarPlayStore
        notFoundCharacter = notFoundCharacterVersar
        linkPlayStore =
          'https://play.google.com/store/apps/details?id=br.com.fractaltecnologia.versar'
        linkAppleStore = 'https://apps.apple.com/us/app/versar/id1558183254'
        break
      case '46':
        qrCodeApple = SeriosAppleStore
        qrCodePlayStore = SeriosPlayStore
        notFoundCharacter = notFoundCharacterSerios
        linkPlayStore =
          'https://play.google.com/store/apps/details?id=br.com.fractaltecnologia.agenda.serios'
        linkAppleStore =
          'https://apps.apple.com/br/app/agenda-serios/id6456263888'
        break

      default:
        qrCodeApple = versarAppleStore
        qrCodePlayStore = versarPlayStore
        notFoundCharacter = notFoundCharacterVersar
        linkPlayStore =
          'https://play.google.com/store/apps/details?id=br.com.fractaltecnologia.versar'
        linkAppleStore = 'https://apps.apple.com/us/app/versar/id1558183254'
        break
    }
    return {
      qrCodeApple,
      qrCodePlayStore,
      notFoundCharacter,
      linkPlayStore,
      linkAppleStore
    }
  }

  const {
    qrCodeApple,
    qrCodePlayStore,
    notFoundCharacter,
    linkPlayStore,
    linkAppleStore
  } = getImageImports(appId)

  return (
    <MenuApp>
      {userOccupations?.[0] === 'parent' ||
      userOccupations?.[0] === 'student' ? (
        <div className={classes.section}>
          <div className={classes.content}>
            <div>
              <h1>Ops!</h1>
              <p>
                <strong>
                  Baixe o nosso aplicativo exclusivo para responsáveis para{' '}
                  <br /> ter acesso a todas as funcionalidades da nossa
                  plataforma.{' '}
                </strong>{' '}
                <br />
                Escaneie o QR Code abaixo com o seu celular e comece a <br />{' '}
                acompanhar o progresso do aluno de forma fácil e conveniente!
              </p>
            </div>
            <div className={classes.qrCodeContainer}>
              <div className={classes.qrCodeSection}>
                <img
                  className={classes.imgQrCodeApple}
                  width={150}
                  src={qrCodeApple}
                  alt='QR code do applicativo para baixar na apple store'
                />
                <a target='_blank' rel='noreferrer' href={linkAppleStore}>
                  <img
                    className={classes.imgQrCodeApple}
                    width={150}
                    src={appleStoreIcon}
                    alt='Icone da apple store'
                  />
                </a>
              </div>
              <div className={classes.qrCodeSection}>
                <img
                  className={classes.imgQrCodePlayStore}
                  width={150}
                  src={qrCodePlayStore}
                  alt='QR code do applicativo para baixar na play store'
                />
                <a target='_blank' rel='noreferrer' href={linkPlayStore}>
                  <img
                    width={150}
                    src={playStoreIcon}
                    alt='Icone da play store'
                  />
                </a>
              </div>
            </div>
          </div>
          <div className={classes.character}>
            <img
              className={classes.characterImg}
              src={notFoundCharacter}
              alt='Uma pessoa segurando uma lupa'
            />
          </div>
        </div>
      ) : (
        <h1 style={{ width: '98%' }}>
          Ops! A página que você <br /> procura não está aqui.
        </h1>
      )}
    </MenuApp>
  )
}

const mapStateToProps = (state, props) => {
  return {
    userOccupations: state.auth.currentOccupation
  }
}
export default connect(mapStateToProps, null)(NotFoundPage)

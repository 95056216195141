import DashboardTemplate from '../components/template/DashboardTemplate'

import Login from '../pages/LoginPage'
import Modules from '../pages/ModulesPage'
import NewModulesPage from '../pages/NewModulesPage'
import NewThemesPage from '../pages/NewThemesPage'
import NewContentsPage from '../pages/NewContentsPage'
import Themes from '../pages/ThemesPage'
import Contents from '../pages/ContentsPage'
import CreateModule from '../pages/CreateModulePage'
import CreateTheme from '../pages/CreateThemePage'
import CreateContents from '../pages/CreateContentsPage'
import VersarPage from '../pages/VersarPage'
import ThemePage from '../pages/ThemePage'
import ModulePage from '../pages/ModulePage'
import ContentPage from '../pages/ContentPage'
import { roles } from '../utils/constants'
import ClassroomsPage from '../pages/ClassroomsPage'
import ClassroomPage from '../pages/ClassroomPage'
import PlanningGrades from '../pages/PlanningGradesPage'
import GradeTemplate from '../pages/GradeTemplatePage'
import PlanningSearch from '../pages/PlanningSearchPage'
import ThemeSchedule from '../pages/ThemeSchedulePage'
import ThemeScheduleDate from '../pages/ThemeScheduleDatePage'
import DeleteProfilePage from '../pages/DeleteProfilePage'
import Settings from '../pages/SettingsPage'
import CreateClassroom from '../pages/CreateClassroomPage'
import SettingsClassroom from '../pages/SettingsClassroomPage'
import CreateStudent from '../pages/CreateStudentPage'
import CreateTeacher from '../pages/CreateTeacherPage'
import ReactivateTeacherPage from '../pages/ReactivateTeacherPage'
import SchoolsList from '../pages/SchoolsListPage'
import Messages from '../pages/MessagesPage'
import NewMessages from '../pages/NewMessagesPage'
import Message from '../pages/MessagePage'
import MessagesInbox from '../pages/MessagesInboxPage'
import SendMessage from '../pages/SendMessagePage'
import Announcements from '../pages/AnnouncementsPage'
import AnnouncementsDetails from '../pages/AnnouncementsDetailsPage'
import AnnouncementsConfirmations from '../pages/AnnouncementsConfirmationsPage'
import Reports from '../pages/ReportsPage'
import ReportClassroom from '../pages/ReportClassroomPage'
import ReportStudent from '../pages/ReportStudentPage'
import ReportStudentByClassroom from '../pages/ReportStudentByClassroomPage'
import ReportsPageTemplate from '../pages/ReportsTemplatePage'
import UtilsPage from '../pages/UtilsPage'
import MaterialsPage from '../pages/MaterialsPage'
import CreateCoordinatorPage from '../pages/CreateCoordinatorPage'
import SocioemotionalPage from '../pages/SocioemotionalPage'
import ReactivateCoordinatorPage from '../pages/ReactivateCoordinatorPage'

/**
 * Only logged users can access these routes
 *
 * - Major precedence when user IS LOGGED:
 *    Overwrites the same paths of the public routes
 * - No match when user IS NOT LOGGED:
 *    Render LoginPage component by default
 */

export const privateRoutes = [
  {
    path: '/modules',
    component: Modules,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.CONTENT_CREATOR]
  },
  {
    path: '/socioemotional',
    component: SocioemotionalPage,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.CONTENT_CREATOR]
  },
  {
    path: '/newmodules',
    component: NewModulesPage,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.CONTENT_CREATOR]
  },
  {
    path: '/newmodules/create',
    component: NewModulesPage,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.CONTENT_CREATOR],
    componentAttribute: {
      isCreate: true
    }
  },
  {
    path: '/newmodules/:moduleId/edit',
    component: NewModulesPage,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.CONTENT_CREATOR],
    componentAttribute: {
      isEdit: true
    }
  },
  {
    path: `/newmodules/:moduleId/themes/create`,
    component: NewThemesPage,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.CONTENT_CREATOR],
    componentAttribute: {
      isCreate: true
    }
  },
  {
    path: '/newthemes',
    component: NewThemesPage,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.CONTENT_CREATOR]
  },
  {
    path: `/newthemes/create`,
    component: NewThemesPage,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.CONTENT_CREATOR],
    componentAttribute: {
      isCreate: true
    }
  },
  {
    path: `/newthemes/:themeId/contents`,
    component: NewThemesPage,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.CONTENT_CREATOR],
    componentAttribute: {
      stepTwo: true
    }
  },
  {
    path: `/newthemes/:themeId/preview`,
    component: NewThemesPage,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.CONTENT_CREATOR],
    componentAttribute: {
      stepThree: true
    }
  },
  {
    path: `/newthemes/:themeId/edit`,
    component: NewThemesPage,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.CONTENT_CREATOR],
    componentAttribute: {
      isEdit: true
    }
  },
  {
    path: `/newcontents/:themeId/create`,
    component: NewContentsPage,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.CONTENT_CREATOR],
    componentAttribute: {
      isCreate: true
    }
  },
  {
    path: `/newcontents/:themeId/edit/:contentId`,
    component: NewContentsPage,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.CONTENT_CREATOR],
    componentAttribute: {
      isEdit: true
    }
  },
  {
    path: '/modules/create',
    component: CreateModule,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.CONTENT_CREATOR]
  },
  {
    path: '/modules/:id',
    component: ModulePage,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.CONTENT_CREATOR]
  },
  {
    path: '/modules/:id/edit',
    component: CreateModule,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.CONTENT_CREATOR]
  },
  {
    path: '/themes',
    component: Themes,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.CONTENT_CREATOR]
  },
  {
    path: '/themes/create',
    component: CreateTheme,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.CONTENT_CREATOR]
  },
  {
    path: '/themes/:id/edit',
    component: CreateTheme,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.CONTENT_CREATOR]
  },
  {
    path: '/themes/:id',
    component: ThemePage,
    template: DashboardTemplate,
    permission: [
      roles.VERSAR_ADMIN,
      roles.SCHOOL_ADMIN,
      roles.CONTENT_CREATOR,
      roles.TEACHER,
      roles.COORDINATOR
    ]
  },
  {
    path: '/contents',
    component: Contents,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.CONTENT_CREATOR]
  },
  {
    path: '/contents/create',
    component: CreateContents,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.CONTENT_CREATOR]
  },
  {
    path: '/contents/:id',
    component: ContentPage,
    template: DashboardTemplate,
    permission: [
      roles.VERSAR_ADMIN,
      roles.SCHOOL_ADMIN,
      roles.CONTENT_CREATOR,
      roles.TEACHER,
      roles.COORDINATOR
    ]
  },
  {
    path: '/contents/:id/edit',
    component: CreateContents,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.CONTENT_CREATOR]
  },
  {
    path: '/classrooms',
    component: ClassroomsPage,
    template: DashboardTemplate,
    permission: [
      roles.VERSAR_ADMIN,
      roles.SCHOOL_ADMIN,
      roles.TEACHER,
      roles.COORDINATOR
    ]
  },
  {
    path: '/classrooms/:id',
    component: ClassroomPage,
    template: DashboardTemplate,
    permission: [
      roles.VERSAR_ADMIN,
      roles.SCHOOL_ADMIN,
      roles.TEACHER,
      roles.COORDINATOR
    ]
  },
  {
    path: '/planning/grades',
    component: PlanningGrades,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN]
  },
  {
    path: '/planning/grades/:gradeId',
    component: GradeTemplate,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN]
  },
  {
    path: '/planning/grades/:gradeId/search',
    component: PlanningSearch,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN]
  },
  {
    path: '/theme-schedule/grades',
    component: PlanningGrades,
    template: DashboardTemplate,
    permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN]
  },
  {
    path: '/theme-schedule/schools',
    component: SchoolsList,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN]
  },
  {
    path: '/theme-schedule/schools/:schoolId/grades',
    component: PlanningGrades,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN]
  },
  {
    path: '/theme-schedule/schools/:schoolId/grades/:gradeId',
    component: ThemeSchedule,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN]
  },
  {
    path: '/theme-schedule/grades/:gradeId',
    component: ThemeSchedule,
    template: DashboardTemplate,
    permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN]
  },
  {
    path: '/theme-schedule/schools/:schoolId/grades/:gradeId/:templateId',
    component: ThemeScheduleDate,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN]
  },
  {
    path: '/theme-schedule/grades/:gradeId/:templateId',
    component: ThemeScheduleDate,
    template: DashboardTemplate,
    permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN]
  },
  {
    path:
      '/theme-schedule/schools/:schoolId/grades/:gradeId/:templateId/search',
    component: PlanningSearch,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN]
  },
  {
    path: '/theme-schedule/grades/:gradeId/:templateId/search',
    component: PlanningSearch,
    template: DashboardTemplate,
    permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN]
  },
  {
    path: '/settings',
    component: Settings,
    template: DashboardTemplate,
    permission: [
      roles.STUDENT,
      roles.PARENT,
      roles.VERSAR_ADMIN,
      roles.SCHOOL_ADMIN,
      roles.COORDINATOR,
      roles.TEACHER
    ],
    defaultFor: [roles.STUDENT, roles.PARENT]
  },
  {
    path: '/settings/delete_profile',
    component: DeleteProfilePage,
    template: DashboardTemplate,
    permission: [
      roles.STUDENT,
      roles.PARENT,
      roles.VERSAR_ADMIN,
      roles.SCHOOL_ADMIN,
      roles.COORDINATOR,
      roles.TEACHER
    ]
  },
  {
    path: '/settings/covers',
    component: Settings,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN]
  },
  {
    path: '/settings/classrooms',
    component: Settings,
    template: DashboardTemplate,
    permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN, roles.COORDINATOR]
  },
  {
    path: '/settings/classrooms/new',
    component: CreateClassroom,
    template: DashboardTemplate,
    permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN, roles.COORDINATOR]
  },
  {
    path: '/settings/classrooms/:classId',
    component: SettingsClassroom,
    template: DashboardTemplate,
    permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN, roles.COORDINATOR]
  },
  {
    path: '/settings/classrooms/:classId/edit',
    component: CreateClassroom,
    template: DashboardTemplate,
    permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN, roles.COORDINATOR]
  },
  {
    path: '/settings/classrooms/:classId/students/new',
    component: CreateStudent,
    template: DashboardTemplate,
    permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN, roles.COORDINATOR]
  },
  {
    path: '/settings/classrooms/:classId/students/:childId',
    component: CreateStudent,
    template: DashboardTemplate,
    permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN, roles.COORDINATOR]
  },
  {
    path: '/settings/teachers',
    component: Settings,
    template: DashboardTemplate,
    permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN, roles.COORDINATOR]
  },
  {
    path: '/settings/teachers/new',
    component: CreateTeacher,
    template: DashboardTemplate,
    permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN]
  },
  {
    path: '/settings/coordinator',
    component: Settings,
    template: DashboardTemplate,
    permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN, roles.COORDINATOR]
  },
  {
    path: '/settings/coordinator/new',
    component: CreateCoordinatorPage,
    template: DashboardTemplate,
    permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN]
  },
  {
    path: '/settings/coordinator/:coordinatorId/edit',
    component: CreateCoordinatorPage,
    template: DashboardTemplate,
    permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN]
  },
  {
    path: '/settings/coordinator/reactivate',
    component: ReactivateCoordinatorPage,
    template: DashboardTemplate,
    permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN]
  },
  {
    path: '/settings/moderation',
    component: Settings,
    template: DashboardTemplate,
    permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN]
  },
  {
    path: '/settings/teachers/reactivate',
    component: ReactivateTeacherPage,
    template: DashboardTemplate,
    permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN]
  },
  {
    path: '/settings/teachers/:teacherId/edit',
    component: CreateTeacher,
    template: DashboardTemplate,
    permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN]
  },
  {
    path: '/settings/layout',
    component: Settings,
    template: DashboardTemplate,
    permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN]
  },
  {
    path: '/settings/historic',
    component: Settings,
    template: DashboardTemplate,
    permission: [roles.SCHOOL_ADMIN, roles.VERSAR_ADMIN]
  },
  {
    path: '/newmessages',
    component: NewMessages,
    template: DashboardTemplate,
    permission: [
      roles.SCHOOL_ADMIN,
      roles.TEACHER,
      roles.SCHOOL_MODERATOR,
      roles.COORDINATOR
    ]
  },
  {
    path: '/newmessages/:chatId',
    component: NewMessages,
    template: DashboardTemplate,
    permission: [
      roles.SCHOOL_ADMIN,
      roles.TEACHER,
      roles.SCHOOL_MODERATOR,
      roles.COORDINATOR
    ]
  },
  {
    path: '/messages',
    component: Messages,
    template: DashboardTemplate,
    permission: [
      roles.SCHOOL_ADMIN,
      roles.TEACHER,
      roles.SCHOOL_MODERATOR,
      roles.COORDINATOR
    ]
  },
  {
    path: '/messages/schools',
    component: SchoolsList,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN]
  },
  {
    path: '/messages/inbox',
    component: MessagesInbox,
    template: DashboardTemplate,
    permission: [
      roles.SCHOOL_ADMIN,
      roles.TEACHER,
      roles.SCHOOL_MODERATOR,
      roles.COORDINATOR
    ]
  },
  {
    path: '/messages/schools/:schoolId/inbox',
    component: MessagesInbox,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN]
  },
  {
    path: '/messages/schools/:schoolId',
    component: Messages,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN]
  },
  {
    path: '/messages/inbox/:communicationId',
    component: Message,
    template: DashboardTemplate,
    permission: [
      roles.SCHOOL_ADMIN,
      roles.VERSAR_ADMIN,
      roles.TEACHER,
      roles.SCHOOL_MODERATOR,
      roles.COORDINATOR
    ]
  },
  {
    path: '/messages/schools/:schoolId/inbox/:communicationId',
    component: Message,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN]
  },
  {
    path: '/messages/create',
    component: SendMessage,
    template: DashboardTemplate,
    permission: [
      roles.SCHOOL_ADMIN,
      roles.TEACHER,
      roles.SCHOOL_MODERATOR,
      roles.COORDINATOR
    ]
  },
  {
    path: '/messages/schools/:schoolId/create',
    component: SendMessage,
    template: DashboardTemplate,
    permission: [roles.VERSAR_ADMIN]
  },
  {
    path: '/announcements',
    component: Announcements,
    template: DashboardTemplate,
    permission: [
      roles.VERSAR_ADMIN,
      roles.SCHOOL_ADMIN,
      roles.TEACHER,
      roles.COORDINATOR
    ],
    default: true
    // defaultFor: roles.TEACHER
  },
  {
    path: '/announcements/:eventId/details',
    component: AnnouncementsDetails,
    template: DashboardTemplate,
    permission: [
      roles.VERSAR_ADMIN,
      roles.SCHOOL_ADMIN,
      roles.TEACHER,
      roles.COORDINATOR
    ]
  },
  {
    path: '/announcements/:eventId/confirmations',
    component: AnnouncementsConfirmations,
    template: DashboardTemplate,
    permission: [
      roles.VERSAR_ADMIN,
      roles.SCHOOL_ADMIN,
      roles.TEACHER,
      roles.COORDINATOR
    ]
  },
  {
    path: '/reports',
    component: Reports,
    template: DashboardTemplate,
    permission: [
      roles.VERSAR_ADMIN,
      roles.SCHOOL_ADMIN,
      roles.TEACHER,
      roles.COORDINATOR
    ]
  },
  {
    path: '/reports/:reportId/classroom',
    component: ReportClassroom,
    template: DashboardTemplate,
    permission: [
      roles.VERSAR_ADMIN,
      roles.SCHOOL_ADMIN,
      roles.TEACHER,
      roles.COORDINATOR
    ]
  },
  {
    path: '/reports/:reportId/classroom/:reportStudentId',
    component: ReportStudent,
    template: DashboardTemplate,
    permission: [
      roles.VERSAR_ADMIN,
      roles.SCHOOL_ADMIN,
      roles.TEACHER,
      roles.COORDINATOR
    ]
  },
  {
    path: '/reports/:reportId/classroom/all/students',
    component: ReportStudentByClassroom,
    template: DashboardTemplate,
    permission: [
      roles.VERSAR_ADMIN,
      roles.SCHOOL_ADMIN,
      roles.TEACHER,
      roles.COORDINATOR
    ]
  },
  // {
  //   path: '/reports/:reportId/classroom/:reportStudentId/edit',
  //   component: ReportStudent,
  //   template: DashboardTemplate,
  //   permission: [roles.VERSAR_ADMIN, roles.SCHOOL_ADMIN, roles.TEACHER]
  // },
  {
    path: '/report_template/:reportTemplateId',
    component: ReportsPageTemplate,
    template: DashboardTemplate,
    permission: [
      roles.VERSAR_ADMIN,
      roles.SCHOOL_ADMIN,
      roles.TEACHER,
      roles.COORDINATOR
    ]
  },
  {
    path: '/utils',
    component: UtilsPage,
    template: DashboardTemplate,
    permission: [
      roles.VERSAR_ADMIN,
      roles.SCHOOL_ADMIN,
      roles.TEACHER,
      roles.COORDINATOR
    ]
  },
  {
    path: '/materials',
    component: MaterialsPage,
    template: DashboardTemplate,
    permission: [
      roles.VERSAR_ADMIN,
      roles.SCHOOL_ADMIN,
      roles.TEACHER,
      roles.COORDINATOR
    ]
  },
  {
    path: '/materials/create',
    component: MaterialsPage,
    template: DashboardTemplate,
    permission: [
      roles.VERSAR_ADMIN,
      roles.SCHOOL_ADMIN,
      roles.TEACHER,
      roles.COORDINATOR
    ],
    componentAttribute: {
      isCreate: true
    }
  },
  {
    path: '/materials/:materialId',
    component: MaterialsPage,
    template: DashboardTemplate,
    permission: [
      roles.VERSAR_ADMIN,
      roles.SCHOOL_ADMIN,
      roles.TEACHER,
      roles.COORDINATOR
    ]
  },
  {
    path: '/materials/:materialId/edit',
    component: MaterialsPage,
    template: DashboardTemplate,
    permission: [
      roles.VERSAR_ADMIN,
      roles.SCHOOL_ADMIN,
      roles.TEACHER,
      roles.COORDINATOR
    ],
    componentAttribute: {
      isEdit: true
    }
  },
  {
    path: '/materials/:materialId/report',
    component: MaterialsPage,
    template: DashboardTemplate,
    permission: [
      roles.VERSAR_ADMIN,
      roles.SCHOOL_ADMIN,
      roles.TEACHER,
      roles.COORDINATOR
    ],
    componentAttribute: {
      isReport: true
    }
  }
]

/**
 * Only not logged users can access these routes
 *
 * - Precedence over same paths of private routes when user IS NOT LOGGED:
 *    Overwrites the login rendering
 * - No match when user IS LOGGED
 *    Redirect to default private route
 */
export const notLoggedRoutes = [
  {
    path: '/',
    component: VersarPage,
    onlyNoAuth: true
  },
  {
    path: '/login',
    component: Login,
    onlyNoAuth: true
  }
]

/**
 * Both logged and not logged users can access these routes
 *
 * - Minor precedence when user IS LOGGED
 *    Is overwritten by the private route with same paths
 * - Major precedence when user IS NOT LOGGED
 *    Overwrites the same paths of the private and not logged routes
 */
export const publicRoutes = [
  // {
  //   path: '/',
  //   component: Home
  // }
]

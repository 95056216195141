import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'

import { makeStyles, useTheme } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import jsonApi from '../../utils/jsonApi'

const createOption = (label, value) => ({
  label,
  value
})

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: 14
  },
  formControl: {
    maxWidth: 540,
    '& .select__control': {
      border: props => props.borderColor,
      borderRadius: '10px',
      minHeight: props => props.height,
      '& .select__indicators': {
        minHeight: props => props.height
      }
    },
    '& .select__placeholder': {
      color: '#e4e4e4',
      fontSize: '1rem'
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 255
    },
    [theme.breakpoints.up('xs')]: {
      minWidth: 255
    },
    [theme.breakpoints.down('475')]: {
      minWidth: '64vw'
    }
  },
  error: {
    color: theme.palette.danger.main,
    fontSize: '0.8rem'
  },
  indicators: {
    padding: '5px 0',
    '& > button.MuiIconButton-root': {
      color: theme.palette.primary.dark,
      padding: '10px 7px'
    }
  }
}))

export default function ReactSelect ({
  id,
  input: { value: inputValue, onChange, onBlur, ...restInput },
  label,
  meta: { touched, invalid, error, active },
  placeholder,
  multiple,
  options = [],
  type,
  change,
  defaultValue,
  handleChange,
  isClearable,
  disabled,
  touch,
  selectRef,
  noUpdateOnBlur,
  ...custom
}) {
  const generalTheme = useTheme()

  const classes = useStyles({
    borderColor:
      touched && error
        ? `1px solid ${generalTheme.palette.danger.main} !important`
        : '1px solid #ADB8CC !important',
    height: 48
  })

  const ClearIndicator = ({ innerRef, innerProps }) => (
    <span ref={innerRef} {...innerProps} className={classes.indicators}>
      <IconButton>
        <HighlightOffIcon />
      </IconButton>
    </span>
  )

  const DropdownIndicator = ({ innerRef, innerProps }) => (
    <span ref={innerRef} {...innerProps} className={classes.indicators}>
      <IconButton>
        <ArrowDropDownIcon />
      </IconButton>
    </span>
  )

  return (
    <div className={classes.root}>
      <Select
        classNamePrefix='select'
        value={
          defaultValue === undefined
            ? null
            : multiple
              ? defaultValue
              : options.find(obj => obj.value === defaultValue)
        }
        isMulti={multiple}
        onBlurResetsInput
        defaultOptions
        isClearable={isClearable}
        blurInputOnSelect={false}
        isDisabled={disabled}
        options={options}
        className={classes.formControl}
        placeholder={placeholder}
        ref={selectRef}
        components={{
          IndicatorSeparator: () => null,
          ClearIndicator,
          DropdownIndicator
        }}
        onChange={handleChange}
        onInputChange={(value, action) => {
          action.action === 'input-blur' &&
            !noUpdateOnBlur &&
            onBlur(defaultValue)
        }}
        onFocus={e => {
          touch(restInput.name)
        }}
        onBlur={() => {
          !noUpdateOnBlur && onBlur(defaultValue)
        }}
        {...restInput}
        {...custom}
      />
      {touched &&
        !active &&
        error && <span className={classes.error}>{error}</span>}
    </div>
  )
}

ReactSelect.propTypes = {
  change: PropTypes.func,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  touch: PropTypes.func,
  id: PropTypes.string,
  input: PropTypes.object,
  isClearable: PropTypes.bool,
  label: PropTypes.string,
  meta: PropTypes.object,
  multiple: PropTypes.bool,
  noUpdateOnBlur: PropTypes.bool,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  selectRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any })
  ]),
  type: PropTypes.string
}

export const CreatableAdvancedSelect = ({
  defaultValue,
  input: { value: inputValue, name: inputName, onBlur, onChange, ...restInput },
  handleChange,
  meta: { touched, invalid, error, active },
  options,
  placeholder,
  multiple,
  requestPath,
  schoolId,
  touch
}) => {
  const generalTheme = useTheme()
  const [hover, setHover] = React.useState(false)
  const classes = useStyles({
    borderColor:
      touched && error
        ? `1px solid ${generalTheme.palette.danger.main} !important`
        : hover
          ? `1px solid ${generalTheme.palette.primary.light} !important`
          : '1px solid #ADB8CC !important',
    height: 40
  })
  const [isLoading, setIsLoading] = useState(false)
  const [newOptions, setNewOptions] = useState(options || [])
  const [value, setValue] = useState(
    defaultValue === undefined
      ? null
      : newOptions.find(op => op.value === defaultValue.value)
  )

  const handleCreate = async inputValueCreate => {
    setIsLoading(true)
    await jsonApi
      .create(requestPath, {
        name: inputValueCreate,
        school_id: schoolId
      })
      .then(response => {
        const newOption = createOption(inputValueCreate, response.data.id)
        setNewOptions(prevState => {
          return [...prevState, newOption]
        })
        setValue(newOption)
        handleChange(newOption, inputName)
        onChange(newOption)
        setIsLoading(false)
      })
      .catch(err => console.error(err))
  }

  const handleInputChange = (newValue, action, event) => {
    action.action === 'input-blur' &&
      action.action !== 'menu-close' &&
      onBlur(newValue)
    if (action.action === 'menu-close' || action.action === 'select-option') {
      handleChange(newValue, inputName)
      setValue(newValue)
      onChange(newValue)
    }
  }

  const ClearIndicator = ({ innerRef, innerProps }) => (
    <span ref={innerRef} {...innerProps} className={classes.indicators}>
      <IconButton>
        <HighlightOffIcon />
      </IconButton>
    </span>
  )

  const DropdownIndicator = ({ innerRef, innerProps }) => (
    <span ref={innerRef} {...innerProps} className={classes.indicators}>
      <IconButton>
        <ArrowDropDownIcon />
      </IconButton>
    </span>
  )

  return (
    <div
      className={classes.root}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <CreatableSelect
        classNamePrefix='select'
        className={classes.formControl}
        components={{
          IndicatorSeparator: () => null,
          ClearIndicator,
          DropdownIndicator
        }}
        defaultOptions
        value={value}
        isClearable
        isDisabled={isLoading}
        isLoading={isLoading}
        isMulti={multiple}
        formatCreateLabel={inputValueSelect => `Criar "${inputValueSelect}" `}
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => 'Não encontrado'}
        onCreateOption={handleCreate}
        options={newOptions}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onFocus={e => {
          touch(inputName)
        }}
        onBlur={() => {
          onBlur(defaultValue)
        }}
        onInputChange={handleInputChange}
        onChange={handleInputChange}
        placeholder={placeholder}
        {...restInput}
      />
    </div>
  )
}

import React from 'react'
import {
  makeStyles,
  styled,
  Switch,
  FormGroup,
  FormControlLabel
} from '@material-ui/core'

import { Field } from 'redux-form'
import StyledSVG from '../shared/StyledSVG'
import cameraSVG from '../../assets/icons/camera-icon.svg'

import { isEmpty } from 'lodash'
import MaterialInput from '../shared/MaterialInput'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import Button from '../button/Button'
import heic2any from 'heic2any'
import { handleAddAvatar } from '../../utils/helpers'

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    padding: '0px 40px',
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    margin: '16px 0px 32px 0px',
    '& p': {
      margin: 0,
      color: '#555555',
      fontSize: 12,
      fontWeight: 400
    },
    '& h2': {
      margin: 0,
      fontSize: 18,
      fontWeight: 600,
      color: '#386093'
    }
  },
  foto_section: {
    '& h2': {
      margin: '0px 0px 8px 0px',
      fontWeight: 600,
      fontSize: 16,
      color: '#375F92'
    }
  },
  input_photo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  photoSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  },
  photoPreview: {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: 140,
    height: 140,
    borderRadius: '50%'
  },
  floatEditPhoto: {
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    bottom: 0,
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: '#386093CC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  floatRemovePhoto: {
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    top: 0,
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: '#EF7C8FCC',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  rmvBtn: {
    zIndex: 1,
    right: '-1px',
    top: '-1px',
    color: '#FFFFFF',
    margin: '5px'
  },
  photoInfo: {
    fontWeight: 400,
    fontSize: 10,
    color: '#868E96'
  },
  photoEmpty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#D9D9D9',
    width: 140,
    height: 140,
    borderRadius: '50%',
    border: '1px dashed #868E96'
  },
  input: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    '& p': {
      margin: 0,
      fontWeight: 600,
      fontSize: 16,
      color: '#375F92'
    }
  },
  description: {
    '& .MuiInput-root': {
      height: 150,
      display: 'flex',
      alignItems: 'flex-start'
    }
  },
  inputs_section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 32,
    marginTop: 32
  },
  switch_section: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24
  },
  switch: {
    display: 'flex'
  },
  switch_label: {
    marginLeft: 13,
    '& h2': {
      fontWeight: 600,
      fontSize: 18,
      margin: 0
    },
    '& span': {
      fontSize: 14,
      fontWeight: 400,
      margin: 0,
      color: '#868E96',
      maxWidth: 380
    }
  },
  permissions: {
    display: 'none',
    flexDirection: 'column',
    gap: 32,
    marginTop: 32,
    marginBottom: 64
  },
  persmissions_description: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    '& h1': {
      margin: 0,
      fontWeight: 600,
      fontSize: 16,
      color: '#386093'
    }
  },
  btnsModal: {
    display: 'flex',
    gap: 12,
    justifyContent: 'flex-end',
    marginBottom: 32
  },
  cancelBtn: {
    width: 146,
    border: '1px solid #EF7C8F',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    color: '#EF7C8F',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #EF7C8F'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  sendBtn: {
    backgroundColor: '#386093',
    width: 148,
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  }
}))

const CustomSwitch = styled(props => (
  <Switch focusVisibleClassName='.Mui-focusVisible' disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#9DD2DA',
        opacity: 1,
        border: 0
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5
      }
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff'
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600]
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3
    }
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: 'rgb(233, 233, 234)',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500
    })
  }
}))

const CreateChatGroupForm = ({
  history,
  handleUnselectForms,
  createChatRSPA,
  dataToCreateGroup,
  fieldValues,
  groupPhoto,
  change
}) => {
  const classes = useStyles()

  const photoRef = React.useRef(null)
  const [photoBlob, setPhotoBlob] = React.useState(null)
  const hasPhoto = photoBlob || groupPhoto
  const [canSubmit, setCanSubmit] = React.useState(null)

  React.useEffect(
    () => {
      if (!isEmpty(dataToCreateGroup) && fieldValues?.name?.length > 0) {
        setCanSubmit(true)
      } else {
        setCanSubmit(false)
      }
    },
    [dataToCreateGroup, fieldValues]
  )

  const handleSubmit = async () => {
    setCanSubmit(false)
    const response = await createChatRSPA(
      Object.assign(
        { ...dataToCreateGroup },
        {
          name: fieldValues.name,
          room_type: 0
        }
      )
    ).then(res => res)
    handleUnselectForms()
    if (response.data.data.id) {
      history.push(`/newmessages/${response.data.data.id}`)
    }
  }

  const handleOnChangePhoto = event => {
    handleAddAvatar(
      event,
      null,
      alert,
      param =>
        setPhotoBlob(prevState => {
          change('photo_group', param)
          return param
        }),
      heic2any
    )
  }

  const handleDeletePhoto = () => {
    setPhotoBlob(null)
    change('photo_group', 'delete')
  }

  const handleOptionChange = option => {
    switch (option) {
      case 'all':
        change('switch', { all: true, restric: false })
        break
      case 'restric':
        change('switch', { all: false, restric: true })
        break
      default:
        break
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        {/* <StyledSVG src={configSVG} width={24} height={24}/> */}
        <h2>Configurações do grupo</h2>
        <p>
          Personalize, configure e explore as ferramentas para criar um espaço
          ideal para o seu novo grupo.
        </p>
      </div>
      <div className={classes.foto_section}>
        <h2>Foto do grupo</h2>
        <div className={classes.input_photo}>
          <input
            accept='.png, .jpg, .jpeg, .heic'
            hidden
            name='photo_group'
            onChange={handleOnChangePhoto}
            ref={photoRef}
            type='file'
          />
          {hasPhoto && fieldValues?.photo_group !== 'delete' ? (
            <div className={classes.photoSection}>
              <div
                style={{
                  backgroundImage: `url(${URL.createObjectURL(
                    photoBlob || groupPhoto
                  )})`
                }}
                className={classes.photoPreview}
              >
                <div
                  onClick={() => photoRef.current && photoRef.current.click()}
                  className={classes.floatEditPhoto}
                >
                  <StyledSVG src={cameraSVG} width={20} height={20} />
                </div>
                <div className={classes.floatRemovePhoto}>
                  <HighlightOffIcon
                    className={classes.rmvBtn}
                    onClick={() => handleDeletePhoto()}
                  />
                </div>
              </div>
              <span className={classes.photoInfo}>
                Min. 200x200 px.<br />.png, .jpg, .jpeg ou .heic
              </span>
            </div>
          ) : (
            <div
              onClick={() => photoRef.current && photoRef.current.click()}
              className={classes.photoSection}
            >
              <div className={classes.photoEmpty}>
                <StyledSVG src={cameraSVG} width={50} height={50} />
              </div>
              <span className={classes.photoInfo}>
                Min. 200x200 px.<br />.png, .jpg, .jpeg ou .heic
              </span>
            </div>
          )}
        </div>
      </div>
      <div className={classes.inputs_section}>
        <div className={classes.input}>
          <p>Nome</p>
          <Field
            component={MaterialInput}
            name='name'
            type='text'
            autoComplete='off'
            placeholder='Nome do grupo'
          />
        </div>
        <div className={`${classes.input} ${classes.description}`}>
          <p>Descrição</p>
          <Field
            component={MaterialInput}
            name='description'
            type='text'
            autoComplete='off'
          />
        </div>
      </div>
      <div className={classes.permissions}>
        <div className={classes.persmissions_description}>
          <h1>Defina quem pode enviar mensagens neste grupo:</h1>
        </div>
        <FormGroup className={classes.switch_section}>
          <div className={classes.switch}>
            <FormControlLabel
              control={
                <CustomSwitch
                  checked={fieldValues?.switch?.all}
                  onClick={() => handleOptionChange('all')}
                  sx={{ m: 1 }}
                />
              }
            />
            <div className={classes.switch_label}>
              <h2>Permitir que todos enviem mensagens</h2>
              <span>Permite que todos possam enviar mensagens facilmente.</span>
            </div>
          </div>

          <div className={classes.switch}>
            <FormControlLabel
              control={
                <CustomSwitch
                  checked={fieldValues?.switch?.restric}
                  onClick={() => handleOptionChange('restric')}
                  sx={{ m: 1 }}
                />
              }
            />
            <div className={classes.switch_label}>
              <h2>Permitir que apenas alguns membros enviem mensagens</h2>
              <span>
                Restrinja o envio de mensagens a membros selecionados.
              </span>
            </div>
          </div>
        </FormGroup>
      </div>
      <div className={classes.btnsModal}>
        <Button
          onclick={() => handleUnselectForms()}
          type='button'
          variant='outlined'
          className={classes.cancelBtn}
        >
          Cancelar
        </Button>
        <Button
          onClick={() => handleSubmit()}
          disabled={!canSubmit}
          className={classes.sendBtn}
        >
          Continuar
        </Button>
      </div>
    </div>
  )
}

// const mapStateToProps = (state, props) => {

// }

// const mapDispatchToProps = dispatch => ({
// })

export default CreateChatGroupForm

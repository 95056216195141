import { all, call, takeLatest, put } from 'redux-saga/effects'
import { showAlertMessage } from '../alert/actions'
import {
  rejectPromiseAction,
  resolvePromiseAction
} from 'redux-saga-promise-actions'

import { updateUserFromSignin } from '../auth/actions'
import { editUser, deleteUserAvatar } from '../classrooms/services'
import { signOut } from '../auth/sagas'
import { addAvatar } from '../classrooms/sagas'
import * as actions from './actions'
import * as services from './services'

export function * getUsersHistoryRequest ({ payload }) {
  try {
    const response = yield call(services.getUsers, payload)
    yield put(actions.getUsersHistorySuccessful(response))
  } catch (error) {
    console.error(error)
    yield put(actions.getUsersHistoryFailure(error))
  }
}

export function * exportUsersHistoryRSPARequest (action) {
  try {
    const { payload: { id } } = action
    const response = yield call(services.exportUsers, id)
    yield put(
      showAlertMessage({
        message: 'Dados dos usuários enviados para o seu e-mail!',
        severity: 'success'
      })
    )
    resolvePromiseAction(action, response)
  } catch (error) {
    console.error(error)
    yield put(
      showAlertMessage({
        message: 'Falha ao exportar o histórico de acesso.',
        severity: 'error'
      })
    )
    rejectPromiseAction(action, error)
  }
}

export function * getUsersRSPA (action) {
  try {
    const { payload: { params } } = action
    const response = yield call(services.getUsersRSPA, params)
    resolvePromiseAction(action, response)
  } catch (error) {
    rejectPromiseAction(action, error)
  }
}

export function * getUserByIdRequest ({ payload }) {
  try {
    const response = yield call(services.getUserById, payload)
    yield put(actions.getUserByIdSuccessful(response))
  } catch (err) {
    yield put(actions.getUserByIdFailure(err))
    console.error(err)
  }
}
export function * forceTemporaryPasswordSaga ({ payload }) {
  const requestBody = {
    email: payload?.email,
    password: payload?.password
  }
  try {
    yield call(services.forceTemporaryPassword, requestBody)
    yield put(actions.forceTemporaryPasswordSuccess())
    yield put(
      showAlertMessage({
        message: 'Senha temporária gerada com sucesso.',
        severity: 'success'
      })
    )
  } catch (err) {
    yield put(actions.forceTemporaryPasswordFailure())
    yield put(
      showAlertMessage({
        message: 'Falha ao gerar senha temporária.',
        severity: 'error'
      })
    )
    console.error(err)
  }
}

export function * editUserRequestRSPA (action) {
  try {
    const { payload: { userId, values: { avatar, ...data } } } = action
    if (avatar === 'delete') {
      yield call(deleteUserAvatar, {
        id: userId
      })
    } else if (avatar && avatar instanceof Blob) {
      yield call(addAvatar, {
        id: userId,
        avatar: avatar
      })
    }
    const response = yield call(editUser, {
      id: userId,
      data: {
        ...data
      }
    })
    yield put(updateUserFromSignin(response))
    resolvePromiseAction(action, response)
    yield put(
      showAlertMessage({
        message: 'Perfil atualizado com sucesso!',
        severity: 'success'
      })
    )
  } catch (error) {
    rejectPromiseAction(action, error)
    yield put(
      showAlertMessage({
        message: 'Falha ao atualizar o perfil.',
        severity: 'error'
      })
    )
  }
}

export function * deleteUserRequestRSPA (action) {
  try {
    const { payload: { userId } } = action
    const response = yield call(services.deleteUser, {
      id: userId
    })
    yield call(signOut)

    resolvePromiseAction(action, response)
  } catch (error) {
    rejectPromiseAction(action, error)
  }
}

export function * getOneUserByEmailRSPA (action) {
  try {
    const { payload: { email } } = action
    const response = yield call(services.getOneUserByEmail, email)
    resolvePromiseAction(action, response)
  } catch (error) {
    rejectPromiseAction(action, error)
  }
}

export function * watchFetchEvents () {
  yield takeLatest(actions.getUsersHistory, getUsersHistoryRequest)
  yield takeLatest(
    actions.exportUsersHistoryRSPA.request,
    exportUsersHistoryRSPARequest
  )
  yield takeLatest(actions.getUsers.request, getUsersRSPA)
  yield takeLatest(actions.getUserById, getUserByIdRequest)
  yield takeLatest(
    actions.forceTemporaryPasswordRequest,
    forceTemporaryPasswordSaga
  )
  yield takeLatest(actions.editUserRSPA.request, editUserRequestRSPA)
  yield takeLatest(actions.deleteUserRSPA.request, deleteUserRequestRSPA)
  yield takeLatest(actions.getOneUserByEmailRSPA.request, getOneUserByEmailRSPA)
}

export default function * usersSagas () {
  yield all([watchFetchEvents()])
}

import { createReducer } from '@reduxjs/toolkit'

const initialState = {
  items: [],
  isFetching: false,
  isError: false,
  isSuccess: false,
  errorMessage: '',
  currentItem: {},
  pagination: {
    total: 1,
    page: 1,
    pageCount: 1
  }
}

const chatsReducer = createReducer(initialState, {
  GET_CHATS: (state, action) => ({
    ...state,
    items: [],
    currentItem: {},
    isFetching: true,
    isSuccess: false
  }),
  GET_CHATS_SUCCESSFUL: (state, action) => ({
    ...state,
    items: action.payload.data,
    isSuccess: true,
    pagination: {
      total: action.payload.meta.total_count,
      pageCount: action.payload.meta.page_count
    },
    isFetching: false
  }),
  GET_CHATS_FAILURE: (state, action) => ({
    ...state,
    isFetching: false,
    items: [],
    isError: true,
    errorMessage: action.payload
  }),
  GET_CHAT_BY_ID: (state, action) => ({
    ...state,
    currentItem: {},
    isFetching: true,
    isSuccess: false
  }),
  GET_CHAT_BY_ID_SUCCESSFUL: (state, action) => ({
    ...state,
    isFetching: false,
    isSuccess: true,
    currentItem: action.payload.data
  }),
  GET_CHAT_BY_ID_FAILURE: (state, action) => ({
    ...state,
    isFetching: false,
    currentItem: {},
    isError: true,
    errorMessage: action.payload
  })
})

export default chatsReducer

import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { all } from 'redux-saga/effects'

// Reducers
import authReducer from './auth/reducers'
import modulesReducer from './modules/reducers'
import themesReducer from './themes/reducers'
import contentsReducer from './contents/reducers'
import gradesReducer from './grades/reducers'
import segmentsReducer from './segments/reducers'
import occupationsReducer from './occupations/reducers'
import categoriesReducer from './categories/reducers'
import skillsReducer from './skills/reducers'
import alertReducer from './alert/reducers'
import schoolReducer from './school/reducers'
import classroomsReducer from './classrooms/reducers'
import teachersReducer from './teachers/reducers'
import coversReducer from './covers/reducers'
import communicationsReducer from './communications/reducers'
import cyclesReducer from './cycles/reducers'
import eventsReducer from './events/reducers'
import reportsReducer from './reports/reducers'
import tagsReducer from './tags/reducers'
import utilsReducer from './utils/reducers'
import paginationReducer from './pagination/reducers'
import usersReducer from './users/reducers'
import sidebarReducer from './sidebar/reducers'
import materialsReducer from './materials/reducers'
import coordinatorsReducer from './coordinators/reducers'
import chatsReducer from './chats/reducer'

// Sagas
import authSagas from './auth/sagas'
import modulesSaga from './modules/sagas'
import gradesSagas from './grades/sagas'
import contentsSagas from './contents/sagas'
import segmentsSagas from './segments/sagas'
import occupationsSagas from './occupations/sagas'
import categoriesSagas from './categories/sagas'
import skillsSagas from './skills/sagas'
import themesSagas from './themes/sagas'
import schoolSagas from './school/sagas'
import classroomsSaga from './classrooms/sagas'
import teachersSaga from './teachers/sagas'
import coversSaga from './covers/sagas'
import communicationsSaga from './communications/sagas'
import cyclesSaga from './cycles/sagas'
import eventsSaga from './events/sagas'
import reportsSaga from './reports/sagas'
import tagsSaga from './tags/sagas'
import utilsSagas from './utils/sagas'
import usersSagas from './users/sagas'
import materialsSagas from './materials/sagas'
import coordinatorsSaga from './coordinators/sagas'
import chatsSagas from './chats/sagas'

export function * rootSaga () {
  yield all([
    authSagas(),
    modulesSaga(),
    gradesSagas(),
    contentsSagas(),
    segmentsSagas(),
    occupationsSagas(),
    categoriesSagas(),
    skillsSagas(),
    themesSagas(),
    schoolSagas(),
    classroomsSaga(),
    teachersSaga(),
    coordinatorsSaga(),
    coversSaga(),
    communicationsSaga(),
    cyclesSaga(),
    eventsSaga(),
    reportsSaga(),
    tagsSaga(),
    utilsSagas(),
    usersSagas(),
    materialsSagas(),
    chatsSagas()
  ])
}

export const appReducer = combineReducers({
  auth: authReducer,
  form: formReducer,
  modules: modulesReducer,
  grades: gradesReducer,
  contents: contentsReducer,
  segments: segmentsReducer,
  occupations: occupationsReducer,
  categories: categoriesReducer,
  skills: skillsReducer,
  themes: themesReducer,
  alert: alertReducer,
  school: schoolReducer,
  classrooms: classroomsReducer,
  teachers: teachersReducer,
  coordinators: coordinatorsReducer,
  covers: coversReducer,
  communications: communicationsReducer,
  cycles: cyclesReducer,
  events: eventsReducer,
  reports: reportsReducer,
  tags: tagsReducer,
  utils: utilsReducer,
  pagination: paginationReducer,
  users: usersReducer,
  sidebar: sidebarReducer,
  materials: materialsReducer,
  chats: chatsReducer
})

const rootReducer = (state, action) => {
  if (action.type === 'app:RESET_STORE') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer

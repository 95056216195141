import { all, call, takeLatest, put } from 'redux-saga/effects'
import { showAlertMessage } from '../alert/actions'
import {
  rejectPromiseAction,
  resolvePromiseAction
} from 'redux-saga-promise-actions'

import * as actions from './actions'
import * as services from './services'

export function * fetchChats ({ payload }) {
  try {
    const response = yield call(services.fetchRooms, payload)
    yield put(
      actions.getChatsSuccessful({ data: response.data, meta: response.meta })
    )
  } catch (error) {
    console.error(error)
    yield put(actions.getChatsFailure(error))
  }
}

export function * fetchChatById ({ payload }) {
  try {
    const response = yield call(services.fetchRoomById, payload)
    yield put(actions.getChatByIdSuccessful({ data: response.data }))
  } catch (error) {
    console.error(error)
    yield put(
      showAlertMessage({
        message: 'Erro ao acessar a sala.',
        severity: 'error'
      })
    )
    yield put(actions.getChatByIdFailure(error))
  }
}

export function * createChats (action) {
  try {
    const { payload } = action
    const response = yield call(services.createRoom, payload)
    resolvePromiseAction(action, response)
  } catch (error) {
    console.error(error)
    rejectPromiseAction(action, error)
    yield put(
      showAlertMessage({
        message: 'Erro ao criar sala.',
        severity: 'error'
      })
    )
  }
}

export function * createMessage (action) {
  try {
    const { payload } = action
    const response = yield call(services.createMessage, payload)
    resolvePromiseAction(action, response)
  } catch (err) {
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * getMessages (action) {
  try {
    const { payload } = action
    if (!payload?.roomId) {
      throw new Error('undefined room_id')
    }
    const response = yield call(services.getMessages, payload)
    resolvePromiseAction(action, response)
  } catch (err) {
    yield put(
      showAlertMessage({
        message: 'Erro ao acessar as mensagens.',
        severity: 'error'
      })
    )
    console.error(err)
    rejectPromiseAction(action, err)
  }
}

export function * fetchRoomsRspa (action) {
  try {
    const { payload } = action
    const response = yield call(services.fetchRooms, payload)
    resolvePromiseAction(action, response)
  } catch (err) {
    rejectPromiseAction(action, err)
  }
}

export function * watchFetchChats () {
  yield takeLatest(actions.getChats, fetchChats)
  yield takeLatest(actions.createChatRSPA.request, createChats)
  yield takeLatest(actions.getChatById, fetchChatById)
  yield takeLatest(actions.createMessageRSPA.request, createMessage)
  yield takeLatest(actions.getMessagesRSPA.request, getMessages)
  yield takeLatest(actions.getRoomsRSPA.request, fetchRoomsRspa)
}

export default function * chatsSagas () {
  yield all([watchFetchChats()])
}

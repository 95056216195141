import React from 'react'

const useWebSocket = ({
  defaultParams,
  roomId,
  fractalId,
  getMessagesRSPA,
  setIsConnected
}) => {
  const [messages, setMessages] = React.useState([])
  const socketRef = React.useRef(null)
  const [error, setError] = React.useState(null)
  const [currentPage, setCurrentPage] = React.useState(1)
  const url = `${
    process.env.REACT_APP_PROJECT_MESSAGE_HOST
  }/cable?room=${roomId}&fractal_id=${fractalId}`

  React.useEffect(() => {
    if (roomId) {
      getMessagesRSPA({
        params: {
          'page[number]': currentPage,
          ...defaultParams
        },
        roomId: roomId
      }).then(res => {
        setMessages(res.data.data.reverse())
      })
    }
  }, [])

  React.useEffect(
    () => {
      try {
        socketRef.current = new WebSocket(url)
        socketRef.current.onopen = () => {
          const subscribeMessage = JSON.stringify({
            command: 'subscribe',
            identifier: JSON.stringify({
              channel: 'RoomChannel',
              room: roomId
            })
          })
          socketRef.current.send(subscribeMessage)
          setIsConnected(true)
        }
        socketRef.current.onerror = event => {
          setIsConnected(false)
        }
        socketRef.current.onmessage = event => {
          const data = JSON.parse(event.data)
          if (data.type === 'ping') return
          if (data.message) {
            const { id, ...rest } = data.message.message
            setMessages(prevMessages => {
              const newValue = [
                ...prevMessages,
                rest.message_type !== 'system' && {
                  id: id,
                  attributes: { ...rest }
                }
              ].filter(item => !!item)
              return newValue
            })
          }
        }

        socketRef.current.onclose = event => {
          socketRef.current?.close()
          setIsConnected(false)
        }

        return () => socketRef.current?.close()
      } catch (error) {
        setIsConnected(false)
        console.error('Erro ao conectar ao WebSocket', error)
        setError(error)
      }
    },
    [url]
  )
  return { messages, error, setMessages, currentPage, setCurrentPage }
}

export default useWebSocket

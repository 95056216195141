import React from 'react'
import moment from 'moment'
import { Waypoint } from 'react-waypoint'

import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import LinearProgress from '@material-ui/core/LinearProgress'

const useStyles = makeStyles(theme => ({
  root: {
    height: 'fit-content',
    maxHeight: 450
  },
  removeStyle: {
    fontSize: 14,
    '& p, h1, h2, h3': {
      margin: 0,
      padding: 0
    }
  }
}))

const NotificationsMenu = ({
  history,
  hasNextPage,
  loadMoreData,
  notifications
}) => {
  const classes = useStyles()

  return (
    <List
      className={classes.root}
      component='nav'
      aria-label='secondary mailbox folders'
    >
      {notifications?.length > 0 &&
        notifications.map((item, index) => {
          const sendMsgAuthor = (
            <span style={{ fontWeight: 900 }}>
              {item?.room_type === 'private'
                ? `${item.room_name} enviou uma mensagem.`
                : `Nova mensagem em ${item.room_name}`}
            </span>
          )
          const msgText = item?.content?.substring(0, 60)
          return (
            <ListItem
              style={{ maxWidth: 300 }}
              key={`listItem-message-${index}`}
              button
              onClick={() => history.push(`/newmessages/${item.room_id}`)}
            >
              <ListItemText
                primary={
                  <span className={classes.removeStyle}>
                    {sendMsgAuthor}
                    <div
                      dangerouslySetInnerHTML={{
                        __html: msgText
                      }}
                    />
                  </span>
                }
                secondary={moment(item?.created_at)
                  .startOf('minute')
                  .fromNow()}
              />
            </ListItem>
          )
        })}
      {hasNextPage && (
        <Waypoint onEnter={loadMoreData}>
          <LinearProgress color='secondary' />
        </Waypoint>
      )}
    </List>
  )
}

export default NotificationsMenu

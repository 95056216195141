import React from 'react'
import { makeStyles, TextareaAutosize, InputBase } from '@material-ui/core'
// import { Field } from 'redux-form'

import StyledSVG from '../shared/StyledSVG'
import Button from '../button/Button'

import moment from 'moment'
import groupRoomIcon from '../../assets/icons/message-group.svg'
import useWebSocket from '../../utils/useWebSocket'
import searchIcon from '../../assets/icons/search-icon.svg'
// import mensageiroGallerySVG from '../../assets/icons/mensageiro-gallery.svg'
// import mensageiroMicrophoneSVG from '../../assets/icons/mensageiro-microphone.svg'
import mensageiroSendSVG from '../../assets/icons/mensageiro-send.svg'
import menuDot from '../../assets/icons/menu-dots.svg'
import UserAvatar from '../shared/UserAvatar'

const useStyles = makeStyles(theme => ({
  message_view: {
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  header: {
    height: 'fit-content',
    backgroundColor: '#ADB8CC33',
    padding: '16px 32px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  avatar_section: {
    display: 'flex',
    gap: 8,
    padding: '8px 0px'
  },
  useravatar: {
    display: 'block !important',
    width: 'fit-content'
  },
  avatar_description: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 4
  },
  message_preview_info: {
    display: 'flex',
    flexDirection: 'column',
    '& h1': {
      color: '#555555',
      fontWeight: 500,
      fontSize: 14,
      margin: 0
    },
    '& span.description': {
      color: '#9F9F9F',
      fontWeight: 400,
      fontSize: 12,
      margin: 0
    }
  },
  chat_name: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  chat_info_label: {
    backgroundColor: '#ADB8CC66',
    width: 'fit-content',
    borderRadius: 4,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px 8px',
    '& span': {
      margin: 0,
      fontSize: 10,
      fontWeight: 400,
      color: '#666666'
    }
  },
  unread_btn: {
    border: '1px solid #375F92',
    height: 24,
    width: 144,
    gap: 10,
    color: '#375F92',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #375F92'
    },
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: '24px',
    textTransform: 'none'
  },
  chat_options: {
    display: 'none',
    alignItems: 'center',
    gap: 8
  },
  main: {
    height: '78%',
    width: '100%',
    display: 'flex',
    overflow: 'hidden'
  },
  messages_section: {
    display: 'flex',
    flexDirection: 'column-reverse',
    width: '100%',
    padding: 20,
    overflow: 'auto',
    position: 'relative'
  },
  footer: {
    backgroundColor: '#ADB8CC33',
    minHeight: '10%',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiInputBase-root': {
      margin: '3px',
      backgroundColor: '#FFFFFF',
      minHeight: '50px',
      width: '95%',
      borderRadius: 30,
      padding: 10,
      overflow: 'auto'
    }
  },
  room_participant_message: {
    display: 'flex',
    margin: '12px 0px 12px 0px',
    width: 'fit-content',
    minWidth: 300,
    minHeight: 40,
    backgroundColor: '#F1F2F3',
    borderRadius: '8px 8px 8px 0px',
    padding: 10
  },
  room_participant_message_wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'left'
  },
  my_message_wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'right'
  },
  my_message: {
    display: 'flex',
    margin: '12px 0px 12px 0px',
    width: 'fit-content',
    minWidth: 300,
    minHeight: 40,
    backgroundColor: '#DAEEF1',
    borderRadius: '8px 8px 0px 8px',
    padding: 10
  },
  message: {
    position: 'relative'
  },
  message_hour: {
    margin: 0,
    position: 'absolute',
    bottom: 5,
    color: '#A4AAB0',
    fontSize: 12
  },
  input_icons: {
    display: 'flex',
    gap: 8,
    marginRight: 30,
    '& div': {
      cursor: 'pointer',
      borderRadius: 6,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: 28,
      height: 28
    },
    '& > div:nth-of-type(-n+2)': {
      backgroundColor: '#D7DCE4'
    }
  },
  send_message: {
    backgroundColor: '#386093'
  }
}))

const MessageComponent = ({
  currentRoom,
  currentUser,
  createMessageRSPA,
  getMessagesRSPA,
  showAlertMessage
}) => {
  const classes = useStyles()
  const [inputValue, setInputValue] = React.useState('')
  const messageSectionRef = React.useRef(null)
  const [loading, setLoading] = React.useState(false)
  const [atBottom, setAtBottom] = React.useState(true)
  const [showingAllMessages, setShowingAllMessages] = React.useState(false)
  const defaultParams = { 'page[size]': 10 }
  const [isConnected, setIsConnected] = React.useState(false)
  const { messages, setMessages, currentPage, setCurrentPage } = useWebSocket({
    roomId: currentRoom.id,
    fractalId: currentUser.attributes.fractal_id,
    getMessagesRSPA,
    defaultParams,
    setIsConnected
  })

  React.useEffect(
    () => {
      const el = messageSectionRef.current
      if (el && atBottom) {
        el.scrollTop = el.scrollHeight
      }
    },
    [messages]
  )

  const handleScroll = () => {
    const el = messageSectionRef.current
    if (!el || loading) return
    if (el.scrollTop === 0) {
      if (!showingAllMessages) {
        setLoading(true)
        const newPageValue = currentPage + 1
        setCurrentPage(newPageValue)
        getMessagesRSPA({
          params: {
            'page[number]': newPageValue,
            ...defaultParams
          },
          roomId: currentRoom.id
        }).then(res => {
          if (currentPage === res.data.meta.page_count) {
            setShowingAllMessages(true)
          }
          setMessages(prev => {
            const newValue = res.data.data.reverse().concat(...prev)
            return newValue
          })
          setLoading(false)
        })
      }
    }
    const atBottomNow = el.scrollHeight - el.scrollTop === el.clientHeight
    setAtBottom(atBottomNow)
  }

  const handleCreateMessage = async () => {
    const data = {
      user_id: currentUser.id,
      room_id: currentRoom.id,
      content: inputValue
    }
    if (isConnected) {
      await createMessageRSPA(data)
      setInputValue('')
    } else {
      showAlertMessage('Erro ao conectar ao chat', 'error')
    }
  }

  const handleHeaderContent = () => {
    const roomName = currentRoom.room_participants.find(item => {
      return +item.user.fractal_id !== +currentUser.attributes.fractal_id
    }).user.name
    let storageData = Object.assign(
      {},
      {
        ...(currentRoom.room_type === 'private'
          ? {
              firstComponent: (
                <UserAvatar
                  avatarUrl={roomName}
                  userName={roomName}
                  withoutUserName
                  className={classes.useravatar}
                />
              ),
              roomName: roomName,
              description: currentRoom.occupation
            }
          : {
              firstComponent: (
                <StyledSVG src={groupRoomIcon} width={40} height={40} />
              ),
              roomName: currentRoom.name,
              description: `${currentRoom?.room_participants?.length} membros`
            })
      }
    )
    return (
      <div className={classes.avatar_section}>
        {storageData.firstComponent}
        <div className={classes.avatar_description}>
          <div className={classes.message_preview_info}>
            <div className={classes.chat_name}>
              <h1>{storageData.roomName}</h1>
            </div>
            <span className='description'>{storageData.description}</span>
          </div>
          {/* <div className={classes.chat_info_label}>
            <span>{currentRoom.idk}</span>
          </div> */}
        </div>
      </div>
    )
  }

  return (
    <div className={classes.message_view}>
      <header className={classes.header}>
        {handleHeaderContent()}
        <div className={classes.chat_options}>
          <Button
            variant='outlined'
            className={classes.unread_btn}
            type='button'
          >
            Marcar como não lida
          </Button>
          <StyledSVG src={searchIcon} width={18} height={18} />
          <StyledSVG src={menuDot} width={18} height={18} />
        </div>
      </header>
      <main style={{ height: '80%', display: 'flex', flexDirection: 'column' }}>
        <div
          ref={messageSectionRef}
          onScroll={handleScroll}
          style={{
            flex: 1,
            overflowY: 'auto',
            display: 'flex',
            flexDirection: 'column',
            padding: 20,
            position: 'relative'
          }}
        >
          {loading && <p>Carregando mais mensagens...</p>}
          {messages?.length > 0 &&
            messages.map((item, index) => {
              const isMyMessage =
                +item.attributes.user_id === +currentUser.attributes.fractal_id
              return (
                <div
                  key={index}
                  className={`${
                    isMyMessage
                      ? classes.my_message_wrapper
                      : classes.room_participant_message_wrapper
                  }`}
                >
                  <div
                    className={`${
                      isMyMessage
                        ? classes.my_message
                        : classes.room_participant_message
                    } ${classes.message}`}
                    key={item.id}
                  >
                    <p>{item.attributes.content}</p>
                    <p
                      className={classes.message_hour}
                      style={isMyMessage ? { right: 5 } : { left: 5 }}
                    >
                      {moment(item.attributes.created_at).format('HH:mm')}
                    </p>
                  </div>
                </div>
              )
            })}
        </div>
      </main>

      <footer className={classes.footer}>
        <InputBase
          fullWidth
          disableUnderline
          value={inputValue}
          onChange={event => setInputValue(event.target.value)}
          placeholder='Escreva a sua mensagem aqui...'
          inputComponent={TextareaAutosize}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              handleCreateMessage()
            }
          }}
          inputProps={{
            minRows: 1,
            maxRows: 6,
            style: {
              resize: 'none',
              marginLeft: 15
            }
          }}
          endAdornment={
            <div className={classes.input_icons}>
              {/* <div>
                <StyledSVG src={mensageiroGallerySVG} />
              </div>
              <div>
                <StyledSVG src={mensageiroMicrophoneSVG} />
              </div> */}
              <div
                onClick={handleCreateMessage}
                className={classes.send_message}
              >
                <StyledSVG src={mensageiroSendSVG} />
              </div>
            </div>
          }
        />
      </footer>
    </div>
  )
}

export default MessageComponent

import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import { occupationsObject } from '../utils/dicts'
import jsonApi from '../utils/jsonApi'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import { isEmpty } from 'lodash'
import { useHistory } from 'react-router-dom'

import { showAlertMessage } from '../store/alert/actions'
import {
  getChats,
  getChatById,
  createMessageRSPA,
  getMessagesRSPA,
  getRoomsRSPA
} from '../store/chats/actions'
import UserAvatar from '../components/shared/UserAvatar'
import StyledSVG from '../components/shared/StyledSVG'
import Button from '../components/button/Button'
import chatBubbleSVG from '../assets/icons/chat-icon.svg'
import crossAddSVG from '../assets/icons/cross-add-icon.svg'
import closeSideBarArrow from '../assets/icons/arrowlfts.svg'
import searchIcon from '../assets/icons/search-icon.svg'
import emptyMessageSVG from '../assets/icons/without-message-figure.svg'
import initialBannerSVG from '../assets/icons/messages-initial-ilustrator.svg'
import groupMessageSVG from '../assets/icons/message-group.svg'
import CreateChatForm from '../components/form/CreateChatForm'
import MessageComponent from '../components/messages/Messages'

const useStyles = makeStyles(theme => ({
  root: {
    width: '98%',
    height: '87vh',
    backgroundColor: '#FFFFFF',
    borderRadius: 16,
    fontFamily: 'Montserrat, Source Sans Pro'
  },
  menu_grid: {
    height: '100%',
    overflow: 'hidden'
  },
  main_content_grid: {
    height: '100%'
  },
  messages_menu: {
    padding: '32px 24px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  messages_menu_header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    '& h1': {
      fontSize: 24,
      fontWeight: 700,
      color: '#4D5E80'
    },
    marginBottom: 32
  },
  messages_menu_buttons: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    alignItems: 'flex-start',
    marginBottom: 32
  },
  creation_button: {
    display: 'flex',
    gap: 8,
    justifyContent: 'center'
  },
  new_group_button: {
    border: '1px solid #386093',
    height: 44,
    width: '11vw',
    gap: 10,
    color: '#386093',
    borderRadius: 8,
    '&:hover': {
      border: '1px solid #386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.5px',
    '& path': {
      stroke: '#386093'
    },
    textTransform: 'none'
  },
  new_message_button_selected: {
    backgroundColor: '#BEBEBE80 !important',
    color: '#7B7B7B80 !important',
    '&:hover': {
      backgroundColor: '#BEBEBE80 !important'
    },
    '& path': {
      stroke: '#7B7B7B80 !important'
    }
  },
  new_message_button: {
    backgroundColor: '#386093',
    height: 44,
    width: '11vw',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.5px',
    '& path': {
      stroke: '#FFFFFF'
    },
    textTransform: 'none'
  },
  messages_menu_filter: {
    display: 'none',
    flexWrap: 'wrap',
    gap: 8,
    alignItems: 'center',
    marginBottom: 32
  },
  chat_info_label: {
    backgroundColor: '#ADB8CC66',
    width: 'fit-content',
    borderRadius: 4,
    height: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px 8px',
    '& span': {
      margin: 0,
      fontSize: 10,
      fontWeight: 400,
      color: '#666666'
    }
  },
  messages_menu_filter_status: {
    cursor: 'pointer',
    backgroundColor: '#E0E3E680',
    borderRadius: 4,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0px 16px',
    '& span': {
      fontSize: 10,
      fontWeight: 500,
      color: '#868E96'
    }
  },
  messages_menu_dropdowns: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    overflowY: 'auto',
    flexGrow: 2
  },
  dropdown_arrow: {
    cursor: 'pointer',
    width: 12,
    height: 12,
    transition: 'all 0.2s ease-in-out',
    '& path': {
      stroke: '#375F92'
    }
  },
  dropdown_expanded: {
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    transition: 'height 0.5s ease-in-out',
    '&::-webkit-scrollbar': {
      width: 0
    }
  },
  dropdown_item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  dropdown_label: {
    margin: 0,
    fontSize: 16,
    fontWeight: 500,
    color: '#375F92'
  },
  empty_messages: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    '& h1': {
      fontWeight: 600,
      fontSize: 32,
      width: 400,
      textAlign: 'center',
      color: '#386093'
    }
  },
  main_content: {
    width: '95%',
    height: '100%',
    borderLeft: '1px solid rgba(173, 184, 204, 0.5)',
    borderRight: '1px solid rgba(173, 184, 204, 0.5)'
  },
  initial_banner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    height: '100%',
    '& h1': {
      textAlign: 'center',
      margin: 0,
      fontWeight: 700,
      fontSize: 32,
      color: '#386093',
      width: 800
    },
    '& span': {
      textAlign: 'center',
      margin: 0,
      fontWeight: 400,
      fontSize: 24,
      color: '#386093',
      width: 600
    }
  },
  message_section: {
    cursor: 'pointer',
    display: 'flex',
    gap: 8,
    borderBottom: '1px solid #868E964D',
    padding: '8px 0px'
  },
  message_preview: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 4
  },
  message_preview_info: {
    display: 'flex',
    flexDirection: 'column',
    '& h1': {
      color: '#555555',
      fontWeight: 500,
      fontSize: 14,
      margin: 0
    },
    '& span.description': {
      color: '#9F9F9F',
      fontWeight: 400,
      fontSize: 12,
      margin: 0
    }
  },
  chat_name: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  pending_view: {
    width: 8,
    height: 8,
    borderRadius: '100%',
    backgroundColor: '#F69E9E'
  },
  search_component: {
    marginTop: '8px',
    marginBottom: '12px',
    padding: '8px 12px',
    borderRadius: 8,
    border: '1px solid #D2D5D8',
    '& input': {
      padding: 0
    }
  },
  useravatar: {
    display: 'block !important',
    width: 'fit-content'
  }
}))

const NewMessagesPage = ({
  componentAttribute,
  match,
  getChats,
  currentUser,
  rooms,
  currentRoom,
  getChatById,
  createMessageRSPA,
  getMessagesRSPA,
  showAlertMessage,
  getRoomsRSPA
}) => {
  const classes = useStyles()
  const history = useHistory()
  const [expandedGroup, setExpandedGroup] = React.useState({
    expanded: false,
    selected: false,
    items: [],
    page: 1,
    meta: {}
  })
  const [expandedDirectMessages, setExpandedDirectMessages] = React.useState({
    expanded: false,
    selected: false,
    items: [],
    page: 1,
    meta: {}
  })
  const [searchRoomInputValue, setSearchRoomInputValue] = React.useState({
    private: '',
    group: ''
  })

  React.useEffect(async () => {
    const roomTypes = ['group', 'private']
    Promise.all(
      roomTypes.map(async (item, index) => {
        await jsonApi
          .findAll('rooms', {
            params: {
              'page[number]': 1,
              'q[room_type_eq]': index,
              'q[s]': 'created_at desc',
              include: 'room_participants.user'
            }
          })
          .then(res => {
            item === 'group'
              ? setExpandedGroup(prev => {
                  return {
                    ...prev,
                    items: prev.items.concat(res.data),
                    meta: res.meta
                  }
                })
              : setExpandedDirectMessages(prev => {
                  return {
                    ...prev,
                    items: prev.items.concat(res.data),
                    meta: res.meta,
                    expanded: res?.data?.length > 1
                  }
                })
          })
      })
    )
  }, [])

  React.useEffect(
    () => {
      if (match?.params?.chatId) {
        getChatById(match.params.chatId)
      }
    },
    [match?.params?.chatId]
  )

  const handleGetRooms = async (page, roomType, processData) => {
    let parsedRoomType = roomType === 'group' ? 0 : 1
    getRoomsRSPA({
      page: page,
      params: {
        'q[room_type_eq]': parsedRoomType,
        'q[s]': 'created_at desc'
      }
    }).then(res => {
      processData(res)
    })
  }

  const fetchMoreRooms = async (page, roomType) => {
    await handleGetRooms(page, roomType, res => {
      roomType === 'group'
        ? setExpandedGroup(prev => {
            return {
              ...prev,
              page: prev.page + 1,
              items: prev.items.concat(res.data),
              meta: res.meta
            }
          })
        : setExpandedDirectMessages(prev => {
            return {
              ...prev,
              page: prev.page + 1,
              items: prev.items.concat(res.data),
              meta: res.meta
            }
          })
    })
  }

  const handleSearchRoom = async (event, type) => {
    if (event.key === 'Enter') {
      await getRoomsRSPA({
        page: 1,
        params: {
          'q[room_type_eq]': type,
          ...(type === 0
            ? { 'q[name_cont]': searchRoomInputValue.group }
            : {
                'q[room_participants_user_name_cont]':
                  searchRoomInputValue.private
              })
        }
      }).then(res => {
        if (type === 0) {
          setExpandedGroup(prev => ({ ...prev, items: res.data, page: 1 }))
        } else {
          setExpandedDirectMessages(prev => ({
            ...prev,
            page: 1,
            items: res.data
          }))
        }
      })
    }
  }

  const handleClickRoom = id => {
    handleUnselectForms()
    history.push(`/newmessages/${id}`)
  }

  const handleUnselectForms = () => {
    setExpandedDirectMessages(prev => {
      return {
        ...prev,
        selected: false
      }
    })
    setExpandedGroup(prev => {
      return {
        ...prev,
        selected: false
      }
    })
  }

  const handleCreateMessageButton = directMessage => {
    if (directMessage) {
      setExpandedDirectMessages(prev => {
        if (expandedGroup.selected) {
          setExpandedGroup(prev => {
            return { ...prev, selected: !prev.selected }
          })
        }
        return { ...prev, selected: !prev.selected }
      })
    } else {
      if (expandedDirectMessages.selected) {
        setExpandedDirectMessages(prev => {
          return { ...prev, selected: !prev.selected }
        })
      }
      setExpandedGroup(prev => {
        return { ...prev, selected: !prev.selected }
      })
    }
  }

  const handleMainContent = () => {
    if (expandedDirectMessages.selected || expandedGroup.selected) {
      return (
        <CreateChatForm
          handleUnselectForms={handleUnselectForms}
          isDirectMsg={expandedDirectMessages.selected}
          isGroupMsg={expandedGroup.selected}
        />
      )
    } else {
      if (!isEmpty(currentRoom) && match?.params?.chatId) {
        return (
          <MessageComponent
            showAlertMessage={showAlertMessage}
            createMessageRSPA={createMessageRSPA}
            getMessagesRSPA={getMessagesRSPA}
            currentUser={currentUser}
            currentRoom={currentRoom}
          />
        )
      } else {
        if (
          expandedDirectMessages?.items.length < 1 ||
          expandedGroup?.items.length < 1
        ) {
          return (
            <div className={classes.empty_messages}>
              <StyledSVG src={emptyMessageSVG} width={530} height={380} />
              <h1>Ainda não há mensagens por aqui!</h1>
            </div>
          )
        } else {
          return (
            <div className={classes.initial_banner}>
              <StyledSVG src={initialBannerSVG} width={530} height={380} />
              <h1>Bem-vindo(a) à sua central de mensagens!</h1>
              <span>
                Selecione uma conversa para visualizar<br /> seu histórico ou
                inicie uma nova conversa.
              </span>
            </div>
          )
        }
      }
    }
  }

  return (
    <Grid className={classes.root} container>
      <Grid
        className={classes.menu_grid}
        item
        xs={2}
        sm={2}
        md={2}
        lg={2}
        xl={2}
      >
        <div className={classes.messages_menu}>
          <div className={classes.messages_menu_header}>
            <StyledSVG src={chatBubbleSVG} />
            <h1>Mensagens</h1>
          </div>
          <div className={classes.messages_menu_buttons}>
            <Button
              onClick={() => handleCreateMessageButton(true)}
              className={`${classes.new_message_button} ${
                expandedDirectMessages.selected
                  ? classes.new_message_button_selected
                  : ''
              }`}
            >
              <div className={classes.creation_button}>
                <span>Nova mensagem</span>
                <StyledSVG src={crossAddSVG} height={14} width={14} />
              </div>
            </Button>
            <Button
              variant='outlined'
              className={classes.new_group_button}
              type='button'
              onClick={() => handleCreateMessageButton()}
            >
              <div className={classes.creation_button}>
                <span>Novo grupo</span>
                <StyledSVG src={crossAddSVG} height={14} width={14} />
              </div>
            </Button>
          </div>
          <div className={classes.messages_menu_filter}>
            <div className={classes.messages_menu_filter_status}>
              <span>Não lidas</span>
            </div>
            <div className={classes.messages_menu_filter_status}>
              <span>Em andamento</span>
            </div>
          </div>
          <div className={classes.messages_menu_dropdowns}>
            <div className={classes.dropdown_item}>
              <p className={classes.dropdown_label}>Grupos</p>
              <StyledSVG
                width={12}
                height={12}
                className={classes.dropdown_arrow}
                src={closeSideBarArrow}
                alt='closeSideBarArrow'
                onClick={() => {
                  setExpandedGroup(prev => ({
                    ...prev,
                    expanded: !expandedGroup.expanded
                  }))
                }}
                style={{
                  transform: !expandedGroup.expanded
                    ? 'rotate(90deg)'
                    : 'rotate(270deg)'
                }}
              />
            </div>
            <div
              className={classes.dropdown_expanded}
              style={
                expandedGroup.expanded && expandedGroup?.items?.length > 0
                  ? { height: '300px', overflow: 'auto' }
                  : { height: '0px' }
              }
            >
              <Input
                disableUnderline
                className={classes.search_component}
                placeholder='Busque pelo nome'
                onChange={event =>
                  setSearchRoomInputValue(prev => ({
                    ...prev,
                    group: event.target.value
                  }))
                }
                onKeyDown={event => {
                  handleSearchRoom(event, 0)
                }}
                value={searchRoomInputValue.group}
                startAdornment={
                  <StyledSVG
                    style={{ marginRight: 4 }}
                    src={searchIcon}
                    width={18}
                    height={18}
                  />
                }
              />
              <InfiniteScroll
                dataLength={expandedGroup.items.length}
                next={() => fetchMoreRooms(expandedGroup.page + 1, 'group')}
                height={'25vh'}
                hasMore={
                  expandedGroup.meta.total_count !== expandedGroup.items.length
                }
                loader={<h4>Carregando...</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Todos os contatos carregados.</b>
                  </p>
                }
              >
                {expandedGroup.items.map((item, index) => {
                  return (
                    <div
                      onClick={() => handleClickRoom(item.id)}
                      key={index}
                      className={classes.message_section}
                    >
                      <StyledSVG src={groupMessageSVG} height={40} width={40} />
                      <div className={classes.message_preview}>
                        <div className={classes.message_preview_info}>
                          <div className={classes.chat_name}>
                            <h1>{item?.name}</h1>
                            {!item?.readed && (
                              <div className={classes.pending_view} />
                            )}
                          </div>
                          <span className='description'>
                            {item?.description}
                          </span>
                        </div>
                        <div className={classes.chat_info_label}>
                          <span>{`${
                            item?.room_participants?.length
                          } membros`}</span>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </InfiniteScroll>
            </div>
            <div className={classes.dropdown_item}>
              <p className={classes.dropdown_label}>Conversas diretas</p>
              <StyledSVG
                width={12}
                height={12}
                className={classes.dropdown_arrow}
                src={closeSideBarArrow}
                alt='closeSideBarArrow'
                onClick={() => {
                  setExpandedDirectMessages(prev => ({
                    ...prev,
                    expanded: !expandedDirectMessages.expanded
                  }))
                }}
                style={{
                  transform: !expandedDirectMessages.expanded
                    ? 'rotate(90deg)'
                    : 'rotate(270deg)'
                }}
              />
            </div>
            <div
              className={classes.dropdown_expanded}
              style={
                expandedDirectMessages.expanded &&
                expandedDirectMessages?.items?.length > 0
                  ? { height: '300px', overflow: 'auto' }
                  : { height: '0px' }
              }
            >
              <Input
                disableUnderline
                className={classes.search_component}
                placeholder='Busque pelo nome'
                onChange={event =>
                  setSearchRoomInputValue(prev => ({
                    ...prev,
                    private: event.target.value
                  }))
                }
                onKeyDown={event => {
                  handleSearchRoom(event, 1)
                }}
                value={searchRoomInputValue.private}
                startAdornment={
                  <StyledSVG
                    style={{ marginRight: 4 }}
                    src={searchIcon}
                    width={18}
                    height={18}
                  />
                }
              />
              <InfiniteScroll
                dataLength={expandedDirectMessages.items.length}
                next={() =>
                  fetchMoreRooms(expandedDirectMessages.page + 1, 'private')
                }
                height={'25vh'}
                hasMore={
                  expandedDirectMessages.meta.total_count !==
                  expandedDirectMessages.items.length
                }
                loader={<h4>Carregando...</h4>}
                endMessage={
                  <p style={{ textAlign: 'center' }}>
                    <b>Todos os contatos carregados.</b>
                  </p>
                }
              >
                {expandedDirectMessages.items.map((item, index) => {
                  const otherRoomParticipant = item?.room_participants.find(
                    item => {
                      return (
                        +item.user.fractal_id !==
                        +currentUser.attributes.fractal_id
                      )
                    }
                  )
                  const roomName = otherRoomParticipant?.user?.name
                  return (
                    <div
                      onClick={() => handleClickRoom(item.id)}
                      key={index}
                      className={classes.message_section}
                    >
                      <UserAvatar
                        avatarUrl={roomName}
                        userName={roomName}
                        withoutUserName
                        className={classes.useravatar}
                      />
                      <div className={classes.message_preview}>
                        <div className={classes.message_preview_info}>
                          <div className={classes.chat_name}>
                            <h1>{roomName}</h1>
                            {item.pending_messages_count > 0 && (
                              <div className={classes.pending_view} />
                            )}
                          </div>
                          <span className='description'>
                            {
                              occupationsObject[
                                otherRoomParticipant?.occupation_name
                              ]
                            }
                          </span>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </InfiniteScroll>
            </div>
          </div>
        </div>
      </Grid>
      <Grid
        className={classes.main_content_grid}
        item
        xs={10}
        sm={10}
        md={10}
        lg={10}
        xl={10}
      >
        <div className={classes.main_content}>{handleMainContent()}</div>
      </Grid>
    </Grid>
  )
}

const mapStateToProps = (state, props) => {
  return {
    currentUser: state.auth.user.data,
    rooms: {
      private_messages: state.chats.items.filter(
        item => item.room_type === 'private'
      ),
      group_messages: state.chats.items.filter(
        item => item.room_type === 'group'
      )
    },
    currentRoom: state.chats.currentItem
  }
}

const mapDispatchToProps = dispatch => ({
  getChats: data => dispatch(getChats(data)),
  getChatById: data => dispatch(getChatById(data)),
  createMessageRSPA: data => dispatch(createMessageRSPA.request(data)),
  getRoomsRSPA: data => dispatch(getRoomsRSPA.request(data)),
  getMessagesRSPA: data => dispatch(getMessagesRSPA.request(data)),
  showAlertMessage: (message, type) =>
    dispatch(
      showAlertMessage({
        message,
        severity: type
      })
    )
})

export default connect(mapStateToProps, mapDispatchToProps)(NewMessagesPage)

import React from 'react'
import { connect } from 'react-redux'
import {
  Field,
  Form,
  reduxForm,
  // formValueSelector,
  change,
  getFormValues
} from 'redux-form'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useHistory } from 'react-router-dom'

import Button from '../button/Button'
import StyledSVG from '../shared/StyledSVG'
import UserAvatar from '../shared/UserAvatar'
import AsyncSelect from '../shared/AsyncSelect'
import CreateChatGroupForm from './CreateChatGroupForm'
import CheckboxInput from '../input/CheckboxInput'
import { roles, colors } from '../../utils/constants'
import { numberFromText } from '../../utils/helpers'
import { occupationsObject } from '../../utils/dicts'
import { createChatRSPA } from '../../store/chats/actions'
import { getUsers } from '../../store/users/actions'
import searchIcon from '../../assets/icons/search-icon.svg'
import arrowToRight from '../../assets/icons/arrow-to-right.svg'
import deleteSVG from '../../assets/icons/pattern-delete-icon.svg'
import removeButton from '../../assets/icons/red-circle-remove.svg'

import { Avatar, makeStyles } from '@material-ui/core'
import { AvatarGroup } from '@material-ui/lab'
import Tooltip from '@material-ui/core/Tooltip'
import Input from '@material-ui/core/Input'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    overflow: 'hidden'
  },
  group_form_root: {
    height: '85%',
    width: '100%'
  },
  header: {
    backgroundColor: '#ADB8CC33',
    color: '#386093',
    padding: '15px 40px',
    '& h1': {
      margin: 0,
      fontWeight: '700',
      fontSize: 16
    }
  },
  destinations_section: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    padding: '20px 40px',
    position: 'relative',
    '& .label': {
      margin: 0
    }
  },
  add_member: {
    margin: 0,
    borderRadius: 4,
    padding: 4,
    backgroundColor: '#EFF0F2',
    cursor: 'pointer'
  },
  destination_form: {
    cursor: 'default',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    position: 'absolute',
    backgroundColor: '#FFFFFF',
    borderRadius: 8,
    top: '15px',
    left: '120px',
    height: '75vh',
    width: '80%',
    border: '1px solid #CED6E0',
    boxShadow: `
      0px 13px 29px 0px #0000001A,
      0px 52px 52px 0px #00000017,
      0px 117px 70px 0px #0000000D,
      0px 208px 83px 0px #00000003,
      0px 326px 91px 0px #00000000
    `,
    overflowY: 'scroll',
    overflowX: 'hidden'
  },
  destination_form_options: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    margin: '4px 0px'
  },
  filter_buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: 8
  },
  filter_section: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 20px'
  },
  horizontal_bar: {
    width: '100%',
    height: 1,
    border: '1px solid #EFF0F2'
  },
  form_wrapper: {
    display: 'none',
    flexDirection: 'column',
    width: '100%',
    '& h1.label': {
      fontSize: 14,
      fontWeight: '600',
      margin: 0,
      padding: '15px 20px'
    },
    marginBottom: 30
  },
  async_select_class: {
    width: 160,
    '& .select__placeholder': {
      color: '#386093 !important',
      fontSize: '14px !important'
    }
  },
  contacts_list: {
    width: '100%',
    marginBottom: 24
  },
  contact: {
    margin: '8px 0px',
    padding: '8px 0px 8px 15px',
    display: 'flex',
    gap: 8,
    transition: 'background-color 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: '#ADB8CC33'
    },
    '& h1.user_name': {
      fontWeight: 500,
      fontSize: 16,
      color: '#555555',
      transition: 'color 0.3s ease-in-out'
    },
    '& p.user_occupation': {
      fontWeight: 400,
      fontSize: 12,
      color: '#9B9B9B',
      transition: 'color 0.3s ease-in-out'
    }
  },
  contact_seleted: {
    '& h1.user_name': {
      fontWeight: 700,
      color: '#386093'
    },
    '& p.user_occupation': {
      color: '#386093'
    },
    backgroundColor: '#E9FAD9',
    '&:hover': {
      backgroundColor: '#E9FAD9'
    }
  },
  contact_name: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& h1': {
      margin: 0
    },
    '& p': {
      margin: 0
    }
  },
  room_member: {
    backgroundColor: '#EFF0F2',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    height: 30,
    padding: 8,
    gap: 8,
    '& .remove_member': {
      cursor: 'pointer'
    }
  },
  members_section: {
    marginTop: 16,
    '& .label': {
      marginBottom: 16,
      fontWeight: 600,
      fontSize: 16,
      margin: 0
    },
    '& .list': {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      gap: 4
    }
  },
  bottom_section: {
    paddingLeft: 24,
    width: '100%',
    height: '100%'
  },
  bottom_buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 16,
    marginRight: 20,
    marginTop: 10
  },
  select_btn: {
    backgroundColor: '#386093',
    height: 44,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#386093'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px'
  },
  remove_filter_btn: {
    height: 44,
    width: 'fit-content',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '& path': {
      stroke: '#386093'
    },
    '& h1': {
      fontWeight: 700,
      fontSize: 14,
      textAlign: 'center',
      color: '#386093',
      textDecorationColor: '#386093',
      textDecoration: 'underline'
    }
  },
  arrow_to_right: {
    '& path': {
      stroke: '#FFFFFF'
    }
  },
  apply_btn: {
    backgroundColor: '#F4A87C',
    width: 120,
    height: 40,
    padding: '14px 48px',
    gap: 10,
    borderRadius: 8,
    '&:hover': {
      backgroundColor: '#F4A87C'
    },
    fontSize: '16px',
    fontWeight: 600,
    textTransform: 'capitalize',
    lineHeight: '19.5px',
    '& .MuiButton-label': {
      fontSize: 14
    }
  },
  search_component: {
    maxWidth: '450px',
    height: 40,
    marginBottom: '12px',
    padding: '8px 12px',
    borderRadius: '8px',
    border: '1px solid #D2D5D8',
    '& input': {
      padding: 0
    }
  },
  search_by_name_section: {
    margin: '16px 0px 10px 0px',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    padding: '0px 20px',
    '& .label': {
      fontSize: 16,
      fontWeight: 600
    }
  },
  edit_members: {
    fontWeight: 600,
    fontSize: 14,
    textDecoration: 'underline',
    color: '#386093',
    textDecorationColor: '#386093',
    cursor: 'pointer',
    margin: 0
  },
  header_wrapper: {
    height: '15%'
  }
}))

const CreateChatForm = ({
  isDirectMsg,
  isGroupMsg,
  handleSubmit,
  onSubmit,
  // submitting,
  touch,
  getUsers,
  change,
  fieldValues,
  handleUnselectForms,
  userOccupations,
  createChatRSPA,
  currentUserId,
  schoolId
}) => {
  const classes = useStyles()
  const history = useHistory()
  const modalRef = React.useRef(null)
  const [inputExpanded, setInputExpanded] = React.useState(false)
  const [currentContactPage, setCurrentContactPage] = React.useState(1)
  const [contactNameFilter, setContactNameFilter] = React.useState('')
  const [contacts, setContacts] = React.useState([])
  const [selectedUsers, setSelectedUsers] = React.useState([])
  const [dataToCreateGroup, setDataToCreateGroup] = React.useState(null)
  const isVersarAdmin = userOccupations?.includes(roles.VERSAR_ADMIN)
  const isAdmin =
    userOccupations?.includes(roles.SCHOOL_ADMIN) ||
    userOccupations?.includes(roles.VERSAR_ADMIN)
  const defaultParams = {
    'q[id_not_in]': currentUserId,
    ...(!isVersarAdmin ? { schoolId: schoolId } : {})
  }

  React.useEffect(
    () => {
      function handleClickOutside (event) {
        if (modalRef.current && !modalRef.current.contains(event.target)) {
          setInputExpanded(false)
        }
      }

      if (inputExpanded) {
        document.addEventListener('mousedown', handleClickOutside)
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    },
    [inputExpanded]
  )

  React.useEffect(
    () => {
      setInputExpanded(false)
      setSelectedUsers([])
    },
    [isDirectMsg, isGroupMsg]
  )

  React.useEffect(async () => {
    handleGetUsers(currentContactPage, res => {
      setContacts(res)
    })
  }, [])

  const handleGetUsers = async (page, processData, params) => {
    await getUsers({
      params: {
        ...defaultParams,
        'page[number]': page,
        'q[contact_from_school_eq]': true,
        include: 'user_occupations.occupation',
        ...params
      }
    }).then(res => {
      processData(res)
    })
  }

  const handleSearchByFilters = () => {
    setCurrentContactPage(1)
    handleGetUsers(
      1,
      res => {
        setContacts(res)
      },
      { 'q[name_cont]': contactNameFilter }
    )
  }

  const handleSeachByName = event => {
    setContactNameFilter(event.target.value)
  }

  const handleRemoveFilters = () => {
    setCurrentContactPage(1)
    handleGetUsers(1, res => {
      setContacts(res)
    })
    setContactNameFilter('')
  }

  const fetchMoreContacts = async page => {
    handleGetUsers(page, res => {
      setContacts(prev => {
        return prev.concat(res)
      })
    })
    setCurrentContactPage(currentContactPage + 1)
  }

  const handleSelectContact = async () => {
    const newChat = {
      app_id: +process.env.REACT_APP_PROJECT_ID,
      room_participants_attributes: [
        {
          user_id: currentUserId,
          is_moderator: !!isAdmin,
          occupation_name: userOccupations?.[0]
        },
        ...selectedUsers.map(item => {
          return {
            user_id: item.user_id,
            is_moderator: false,
            occupation_name: item.user_occupation
          }
        })
      ]
    }
    if (isDirectMsg) {
      const response = await createChatRSPA(
        Object.assign(newChat, {
          room_type: 1
        })
      ).then(res => res)
      handleUnselectForms()
      if (response.data.data.id) {
        history.push(`/newmessages/${response.data.data.id}`)
      }
    } else {
      setDataToCreateGroup(newChat)
      setInputExpanded(!inputExpanded)
    }
  }

  const handleCheck = (event, user) => {
    if (isDirectMsg) {
      setSelectedUsers(prev => {
        return [
          {
            user_id: user.id,
            user_name: user.name,
            user_avatar: user.avatar_url,
            user_occupation: user.user_occupations?.[0].occupation.name
          }
        ]
      })
    } else {
      setSelectedUsers(prevSelected => {
        if (event.target.checked) {
          return [
            ...prevSelected,
            {
              user_id: user.id,
              user_name: user.name,
              user_avatar: user.avatar_url,
              user_occupation: user.user_occupations?.[0].occupation.name
            }
          ]
        } else {
          return prevSelected.filter(u => u.user_id !== user.id)
        }
      })
    }
  }

  const handleMemberSectionContent = () => {
    let storageData = Object.assign(
      {},
      {
        ...(isDirectMsg
          ? {
              firstLabel: 'Nova mensagem',
              secondLabel: 'Para',
              memberInputComponent: (
                <p
                  onClick={() => setInputExpanded(!inputExpanded)}
                  className={classes.add_member}
                >
                  Clique aqui para selecionar um contato
                </p>
              )
            }
          : {
              firstLabel: 'Novo grupo',
              secondLabel: 'Membros',
              memberInputComponent: (
                <>
                  {selectedUsers.length > 0 && !inputExpanded ? (
                    <>
                      <AvatarGroup max={5} spacing={1}>
                        {selectedUsers.map((user, idx) => {
                          const index =
                            numberFromText(user.user_name?.slice(0, 2)) %
                            colors.length
                          return (
                            <Tooltip
                              key={`user[${idx}]`}
                              title={user.user_name}
                              arial-label='usuario'
                            >
                              <Avatar
                                style={{
                                  height: '30px',
                                  width: '30px',
                                  backgroundColor:
                                    // eslint-disable-next-line standard/computed-property-even-spacing
                                    colors[index]
                                }}
                              >
                                <p
                                  style={{
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    lineHeight: '17px',
                                    letterSpacing: '0em',
                                    textAlign: 'left'
                                  }}
                                >
                                  {user.user_name[0]}
                                </p>
                              </Avatar>
                            </Tooltip>
                          )
                        })}
                      </AvatarGroup>
                      <p
                        onClick={() => setInputExpanded(true)}
                        className={classes.edit_members}
                      >
                        Editar
                      </p>
                    </>
                  ) : (
                    <p
                      onClick={() => setInputExpanded(!inputExpanded)}
                      className={classes.add_member}
                    >
                      Clique aqui para adicionar membros
                    </p>
                  )}
                </>
              )
            })
      }
    )

    return (
      <div className={classes.header_wrapper}>
        <div className={classes.header}>
          <h1>{storageData.firstLabel}</h1>
        </div>
        <div className={classes.destinations_section}>
          <p className='label'>{storageData.secondLabel}</p>
          {storageData.memberInputComponent}
          {inputExpanded && (
            <div ref={modalRef} className={classes.destination_form}>
              <div className={classes.form_wrapper}>
                <h1 className='label'>Filtros:</h1>
                <div className={classes.filter_section}>
                  <div className={classes.destination_form_options}>
                    <Field
                      component={AsyncSelect}
                      rootStyle={classes.async_select_class}
                      id='occupation_id'
                      name='occupation_id'
                      placeholder='Ocupação'
                      optionsObject={occupationsObject}
                      searchParam='q[title_cont]'
                      touch={touch}
                      request={{
                        path: 'occupations'
                      }}
                    />
                    <Field
                      component={AsyncSelect}
                      rootStyle={classes.async_select_class}
                      defaultOptions={false}
                      isClearable
                      id='grade_id'
                      name='grade_id'
                      placeholder='Série'
                      // handleAsyncChange={handleChange}
                      searchParam='q[name_cont]'
                      request={{
                        path: 'grade',
                        params: {}
                      }}
                      touch={touch}
                    />
                    <Field
                      component={AsyncSelect}
                      rootStyle={classes.async_select_class}
                      defaultOptions={false}
                      isClearable
                      id='classroom_id'
                      name='classroom_id'
                      placeholder='Turma'
                      // handleAsyncChange={handleChange}
                      searchParam='q[name_cont]'
                      request={{
                        path: 'classroom',
                        params: {}
                      }}
                      touch={touch}
                    />
                  </div>
                  <div className={classes.filter_buttons}>
                    <div
                      onClick={() => handleRemoveFilters()}
                      className={classes.remove_filter_btn}
                    >
                      <h1>Limpar</h1>
                      <div className={classes.delete_button}>
                        <StyledSVG width={20} height={20} src={deleteSVG} />
                      </div>
                    </div>
                    <Button
                      onClick={() => handleSearchByFilters()}
                      endIcon={
                        <div className={classes.arrow_to_right}>
                          <StyledSVG
                            width={15}
                            height={15}
                            src={arrowToRight}
                          />
                        </div>
                      }
                      className={classes.apply_btn}
                    >
                      Aplicar
                    </Button>
                  </div>
                </div>
              </div>
              <div className={classes.horizontal_bar} />
              <div className={classes.search_by_name_section}>
                <h1 className='label'>Selecione o contato</h1>
                <Input
                  disableUnderline
                  value={contactNameFilter}
                  className={classes.search_component}
                  placeholder='Busque pelo nome'
                  onKeyDown={e => {
                    if (e.key === 'Enter') {
                      handleSearchByFilters()
                    }
                  }}
                  onChange={e => handleSeachByName(e)}
                  startAdornment={
                    <StyledSVG
                      style={{ marginRight: 4 }}
                      src={searchIcon}
                      width={18}
                      height={18}
                    />
                  }
                />
              </div>
              <div className={classes.horizontal_bar} />
              <div className={classes.contacts_list}>
                <InfiniteScroll
                  dataLength={contacts.length}
                  next={() => fetchMoreContacts(currentContactPage + 1)}
                  height={'25vh'}
                  hasMore
                  loader={<h4>Carregando...</h4>}
                  endMessage={
                    <p style={{ textAlign: 'center' }}>
                      <b>Todos os contatos carregados.</b>
                    </p>
                  }
                >
                  {contacts.map((item, index) => {
                    const isSelected = selectedUsers.some(
                      u => u.user_id === item.id
                    )
                    return (
                      <div
                        className={`${classes.contact} ${isSelected &&
                          classes.contact_seleted}`}
                        key={index}
                      >
                        <CheckboxInput
                          name={`user_${item.id}`}
                          checked={isSelected}
                          handleCheck={event => handleCheck(event, item)}
                        />

                        <UserAvatar
                          avatarUrl={item?.avata_url}
                          userName={item.name}
                          withoutUserName
                        />
                        <div className={classes.contact_name}>
                          <h1 className='user_name'>{item.name}</h1>
                          <p className='user_occupation'>
                            {
                              occupationsObject[
                                item.user_occupations?.[0].occupation.name
                              ]
                            }
                          </p>
                        </div>
                      </div>
                    )
                  })}
                </InfiniteScroll>
              </div>
              <div className={classes.horizontal_bar} />
              <div className={classes.bottom_section}>
                {selectedUsers.length > 0 && (
                  <div className={classes.members_section}>
                    <h1 className='label'>Membros:</h1>
                    <div className='list'>
                      {selectedUsers.map((item, index) => {
                        return (
                          <div key={index} className={classes.room_member}>
                            <UserAvatar
                              avatarStyle={{ width: 24, height: 24 }}
                              avatarUrl={item?.user_avatar}
                              userName={item.user_name}
                            />
                            <StyledSVG
                              className='remove_member'
                              onClick={() =>
                                setSelectedUsers(prev => {
                                  return prev.filter(
                                    i => +i.user_id !== +item.user_id
                                  )
                                })
                              }
                              src={removeButton}
                              width={14}
                              height={14}
                            />
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
                <div className={classes.bottom_buttons}>
                  <Button
                    onclick={() => handleSelectContact()}
                    className={classes.select_btn}
                    disabled={selectedUsers?.length < 1}
                  >
                    Selecionar
                  </Button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    )
  }

  return (
    <Form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.root}>
        {handleMemberSectionContent()}
        <div className={classes.horizontal_bar} />
        {!isDirectMsg && (
          <div className={classes.group_form_root}>
            <CreateChatGroupForm
              history={history}
              handleUnselectForms={handleUnselectForms}
              createChatRSPA={createChatRSPA}
              dataToCreateGroup={dataToCreateGroup}
              change={change}
              fieldValues={fieldValues}
            />
          </div>
        )}
      </div>
    </Form>
  )
}

const mapStateToProps = (state, props) => {
  return {
    userOccupations: state.auth.currentOccupation,
    fieldValues: getFormValues('createChatForm')(state),
    currentUserId: state.auth.user.data.id,
    schoolId: state.school?.currentSchool?.school_id
  }
}

const mapDispatchToProps = dispatch => ({
  createChatRSPA: data => dispatch(createChatRSPA.request(data)),
  getUsers: data => dispatch(getUsers.request(data)),
  change: (field, data) => dispatch(change('createChatForm', field, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(
  reduxForm({
    form: 'createChatForm',
    // enableReinitialize: true,
    // touchOnChange: true,
    // touchOnBlur: true,
    onSubmit: async (values, dispatch, props) => {}
  })(CreateChatForm)
)

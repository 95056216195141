import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import IconButton from '@material-ui/core/IconButton'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import Search from '@material-ui/icons/Search'

const InputComponent = ({
  input,
  hidden,
  placeholder,
  type,
  meta: { touched, error, valid },
  allowClear,
  autoComplete,
  endIcon,
  endBtnFunction,
  startAdornment,
  validateField,
  ...rest
}) => {
  validateField && validateField(valid)
  const generalTheme = useTheme()
  const useStyles = makeStyles(theme => ({
    root: {
      marginBottom: 14,
      display: 'flex',
      flexFlow: 'column',
      '& svg.MuiSvgIcon-root': {
        color: '#386093'
      }
    },
    input: {
      background: 'white',
      height: '48px',
      border: props => props.borderColor,
      borderRadius: '8px',
      paddingLeft: '10px',
      '& .MuiInput-input': {
        marginBottom: '2px !important',
        border: 'none',
        borderRadius: '8px'
      }
    },
    error: {
      color: theme.palette.danger.main,
      fontSize: '0.8rem'
    }
  }))
  const classes = useStyles({
    borderColor:
      touched && error
        ? `1px solid ${generalTheme.palette.danger.main} !important`
        : '1px solid #ADB8CC'
  })
  return (
    <div>
      <div className={classes.root}>
        <Input
          {...input}
          {...rest}
          disableUnderline
          type={type}
          autoComplete={autoComplete}
          className={classes.input}
          placeholder={placeholder}
          hidden={hidden}
          startAdornment={startAdornment}
          endAdornment={
            allowClear && input.value !== '' ? (
              <InputAdornment position='end'>
                <IconButton onClick={() => input.onChange('')}>
                  <HighlightOffIcon />
                </IconButton>
              </InputAdornment>
            ) : input.value !== '' && endBtnFunction ? (
              <InputAdornment position='end'>
                <IconButton onClick={() => endBtnFunction()}>
                  <Search />
                </IconButton>
              </InputAdornment>
            ) : null
          }
        />
        {touched && error && <span className={classes.error}>{error}</span>}
      </div>
    </div>
  )
}

export default InputComponent

import { createAction } from '@reduxjs/toolkit'
import { createPromiseAction } from 'redux-saga-promise-actions'

export const getChats = createAction('GET_CHATS')
export const getChatsSuccessful = createAction('GET_CHATS_SUCCESSFUL')
export const getChatsFailure = createAction('GET_CHATS_FAILURE')

export const createChatRSPA = createPromiseAction('rspa: CREATE_CHAT')()
export const createChatSuccessful = createAction('CREATE_CHAT_SUCCESSFUL')
export const createChatFailure = createAction('CREATE_CHAT_FAILURE')

export const getChatById = createAction('GET_CHAT_BY_ID')
export const getChatByIdSuccessful = createAction('GET_CHAT_BY_ID_SUCCESSFUL')
export const getChatByIdFailure = createAction('GET_CHAT_BY_ID_FAILURE')

export const deleteChat = createAction('DELETE_CHAT')
export const deleteChatSuccessful = createAction('DELETE_CHAT_SUCCESSFUL')
export const deleteChatFailure = createAction('DELETE_CHAT_FAILURE')

export const createMessageRSPA = createPromiseAction('rspa: CREATE_MESSAGE')()
export const getMessagesRSPA = createPromiseAction('rspa: GET_MESSAGES')()
export const getRoomsRSPA = createPromiseAction('rspa: GET_ROOMS')()

// export const exportMaterialReportRSPA = createPromiseAction(
//   'rspa: EXPORT_MATERIAL_REPORT'
// )()
